.scheme-tab {
  text-transform: none !important;
  font-size: 15px !important;
  line-height: 18px !important;
  font-weight: 500 !important;
}

.target-outer {
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  padding: 12px 16px;
  margin-bottom: 10px;
}

.target-title {
  color: #111111;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.target-description {
  color: #111111;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.target-validity {
  color: #606060;
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px;
}

.back-bar-small:before {
  display: block;
  content: "";
  margin-top: 0;
  height: 4px;
  width: 100%;
  /*background-color: #d8d8d8;*/
  /*background-size: 10px 10px !important;*/
  background-position: 0 -23px;
}

.inner-bar-small {
  background-color: #58a159;
  height: 4px;
  margin-top: -4px;
  border-radius: 0px;
  width: 0;
  transition: width 0.6s ease;
}

.inner-bar-small-green {
  background-color: #58a159;
  height: 4px;
  margin-top: -4px;
  border-radius: 0px;
  width: 0;
  transition: width 0.6s ease;
}

.inner-bar-small-black {
  background-color: #606060;
  height: 4px;
  margin-top: -4px;
  border-radius: 0px;
  width: 0;
  transition: width 0.6s ease;
}

.values-and-rewards {
  height: 42px;
  margin-top: 3px;
  position: relative;
}

.stone {
  font-size: 12px;
  line-height: 18px;
}

.flags {
  position: relative;
}

.milestone-flag {
  bottom: -1px;
  float: left;
  position: absolute;
}

.milestone-statue {
  bottom: 2px;
  float: left;
  position: absolute;
}

.progress-target {
  margin-right: 11px;
}

.current-indicator {
  position: absolute;
  bottom: 27px;
  padding: 2px 8px;
  text-align: center;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.reward {
  font-size: 12px;
  line-height: 18px;
}

.target-message {
  display: flex;
  justify-content: center;
  margin-top: 17px;
  text-align: center;
  margin-bottom: 7px;
}

.message-container {
  border-radius: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
  padding: 9px 20px;
  background-color: #ffeac6;
  color: #111111;
}

.achieved-target-img {
  margin-left: calc(100% - 43px);
  height: 54px;
}

.cong-text {
  font-size: 12px;
  line-height: 16px;
}

.cong-reward {
  margin-top: 3px;
  font-size: 15px;
  line-height: 16px;
  font-weight: bold;
}

.values-and-points-box {
  float: left;
  position: absolute;
  text-align: right;
}

.greenText {
  color: #58a159 !important;
}

.no-targets-for-now {
  padding: 0 60px;
  text-align: center;
  margin-top: 10%;
}

.no-past-scheme {
  padding: 0 60px;
  text-align: center;
  margin-top: 10%;
  color: #212121;
  font-size: 16px;
  font-weight: 500;
}

.no-targets-for-now .label {
  font-size: 18px;
  color: #111111;
  line-height: 18px;
}

.no-targets-for-now .text {
  font-size: 13px;
  line-height: 22px;
  color: #606060;
  margin-top: 13px;
}

.no-targets-for-now .home-btn {
  margin-top: 40px;
}

.new-target-alert {
  border: 1px solid #da5c34;
  border-radius: 10px;
  color: #da5c34;
  padding: 3px 8px 2px 8px;
  font-size: 10px;
  line-height: 14px;
  margin-left: 10px;
}

.days-left-alert {
  border: 1px solid #da5c34;
  border-radius: 4px;
  color: #da5c34;
  font-size: 12px;
  line-height: 14px;
  padding: 3px 8px;
}

.target-completed-alert {
  background-color: #58a159;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  padding: 3px 8px;
}

.details-outer {
  background-color: #2c552d;
  padding: 17px 25px 42px 25px;
}

.details-outer .scheme-name {
  color: #ffffff;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
}

.details-outer .scheme-description {
  color: #ffffff;
  font-size: 13px;
  line-height: 15px;
  margin-top: 6px;
  font-weight: 500;
  opacity: 0.9;
}

.details-outer .scheme-validity {
  color: #ffffff;
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
  opacity: 0.78;
}

.target-details {
  padding: 0 10px;
}

.details-reward {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  color: #000000;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding: 16px;
  margin-top: -24px;
}

.trns-history {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  color: #000000;
  font-size: 14px;
  line-height: 16px;
  padding: 16px;
  margin-top: 10px;
}

.scheme-rules {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  padding: 12px 16px;
  margin-top: 10px;
}

.scheme-rules .scheme-rule-label {
  color: #000000;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.scheme-rules .scheme-rule-title {
  color: #111111;
  font-size: 12px;
  line-height: 20px;
}

.warning-banner {
  background-color: #fffdde;
  padding: 14px 47px;
}

.warning-text {
  font-size: 12px;
  color: #727272;
  font-style: italic;
  font-weight: 500;
  line-height: 14px;
}

.top-scheme-item {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  padding: 8px 16px;
  margin-top: 10px;
}

.top-scheme-item .title {
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 0;
}

.included-transactions {
  padding: 16px 10px;
}

.included-transactions .label {
  color: #111111;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
}

.included-transactions .transactions {
  padding-top: 12px;
}

.target-transaction {
  background-color: #ffffff;
  padding: 18px 14px;
  border: 1px solid #cdcaca;
}

.target-txn-label {
  color: #606060;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.target-txn-amount {
  color: #111111;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

.schemes-outer {
  padding: 0px 10px;
  /*  background-color: #f7f7f7;
*/
}

.credit-text {
  color: #58a159;
}

.debit-text {
  color: #d0021b;
}

.scheme-rule-tag {
  color: #606060;
  font-size: 13px;
  line-height: 17px;
  margin: 10px 0;
}

.rule-text {
  font-size: 12px;
  line-height: 20px;
  color: #111111;
  padding: 6px 15px;
}

.rule-include-text {
  font-size: 12px;
  line-height: 20px;
  color: #58a159;
  padding: 6px 15px;
  border-left: 1px solid #c6c6c6;
}

.rule-exclusion-text {
  font-size: 12px;
  line-height: 20px;
  color: #d0021b;
  padding: 6px 15px;
  border-left: 1px solid #c6c6c6;
}

.detail-reward {
  color: #000;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
}

.jt-col-12 {
  width: 12%;
  float: left;
  position: relative;
}

.jt-col-78 {
  width: 78%;
  float: left;
  position: relative;
}

.jt-col-15 {
  width: 15%;
  float: left;
  position: relative;
}

.jt-col-94 {
  width: 94%;
  float: left;
  position: relative;
}

.jt-col-100 {
  width: 100%;
  float: left;
  position: relative;
}

.jt-col-75 {
  width: 75%;
  float: left;
  position: relative;
}

.jt-col-70 {
  width: 70%;
  float: left;
  position: relative;
}

.jt-col-30 {
  width: 30%;
  float: left;
  position: relative;
}

.jt-col-25 {
  width: 25%;
  float: left;
  position: relative;
}

.jt-col-35 {
  width: 35%;
  float: left;
  position: relative;
}

.jt-col-50 {
  width: 50%;
  float: left;
  position: relative;
}

.jt-col-60 {
  width: 60%;
  float: left;
  position: relative;
}

.jt-col-40 {
  width: 40%;
  float: left;
  position: relative;
}

.jt-col-20 {
  width: 20%;
  float: left;
  position: relative;
}

.jt-col-15 {
  width: 15%;
  float: left;
  position: relative;
}

.jt-col-85 {
  width: 85%;
  float: left;
  position: relative;
}

.jt-col-80 {
  width: 80%;
  float: left;
  position: relative;
}

.jt-col-6 {
  width: 6%;
  float: left;
  position: relative;
}

.jt-col-65 {
  width: 65%;
  float: left;
  position: relative;
}

.jt-col-33 {
  width: 33%;
  float: left;
  position: relative;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.centralize {
  text-align: center;
}

.row {
  width: unset !important;
}
