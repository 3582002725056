.store-banner {
  width: 100%;
}

/*.store-selector-wrapper {
  margin-top: 10px;
}
*/

.med-text {
  font-weight: 500;
}

.store-search-box {
  position: absolute;
  bottom: 30px;
  width: 100%;
  max-width: 800px;
  left: calc(50% - 400px);
  text-align: center;
}

.no-store-box {
  background-color: #EFEFEF;
  text-align: center;
  padding: 21px;
  font-size: 16px;
  line-height: 30px;
  margin: 20px 15px;
}

.store-selector-header {
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
  width: 100%;
}

.store-wrapper {
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 15px;
}

.store-name {
  font-size: 16px;
  font-weight: 500;
}

.store-address {
  color: gray;
  font-size: 13px;
  margin-top: 5px;
}

.store-closed {
  text-align: center;
  color: red;
  font-size: 12px;
  padding: 2px 10px;
  width: 50px;
  border-radius: 4px;
  border: 1px solid red;
  display: inline-block;
}

.store-open {
  text-align: center;
  color: green;
  font-size: 12px;
  padding: 2px 10px;
  width: 50px;
  border-radius: 4px;
  border: 1px solid green;
  display: inline-block;
}

.distance-indicator {
    display: inline-block;
    padding-left: 13px;
    line-height: 22px;
    font-size: 14px;
    color: #8F8F8F;
}

.store-info-wrapper {
  background-color: #62B04D;
  padding: 0px 50px 40px 50px;
  color: #fff;
}

.store-info-name {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}

.store-s-address {
  font-size: 13px;
  line-height: 17px;
}

.store-list {
  margin: 20px 50px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(auto, 450px));
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.store-cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(98, 176, 77, 0.2);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  margin-top: -84px;
}

.cover-overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(98, 176, 77, 0.3);
   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2)
}

@media(max-width: 500px) {
  .store-list{
    gap: 15px;
    margin: 20px 15px;
  }

  .store-search-box {
    position: absolute;
    bottom: 20px;
    width: 100%;
    max-width: 800px;
    left: 0;
    text-align: center;
}
}