@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@200&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --ff-primary: "DM Sans";
}

*, *::before, *::after {
  font-family: "DM Sans";
  font-family: var(--ff-primary);
  /* box-sizing: border-box; */
}

.App {
  min-height: 100vh;
}

.banner-image {
  width: 100%;
}

/*Store*/
.store-banner {
  width: 100%;
}

.errorText {
  color: red;
}

.j24-tooltip {
  position: absolute;
  color: #8F8F8F;
  top: -30px;
  font-size: 12px;
  line-height: 140%;
  border: 1px solid #8F8F8F;
  padding: 3px 10px;
  border-radius: 4px;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 12%);
}

.arrow_box {
 position: absolute;
  color: #8F8F8F;
  top: -35px;
  font-size: 12px;
  line-height: 140%;
  border: 1px solid #8F8F8F;
  padding: 3px 10px;
  border-radius: 4px;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 12%);
  right: 0;
  background-color: #fff;
}
.arrow_box:after, .arrow_box:before {
  top: 100%;
  left: 64%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}
.arrow_box:before {
  border-color: rgba(223, 223, 223, 0);
  border-top-color: #8F8F8F;
  border-width: 9px;
  margin-left: -9px;
}

/*.store-selector-wrapper {
  margin-top: 10px;
}
*/
.store-selector-header {
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
}

.top-ten {
  margin-top: 10px;
}

.card {
  box-shadow: 2px 2px 6px rgba(154, 153, 153, 0.2);
  border-radius: 4px;
  text-align: center;
  margin-bottom: 20px;
}

.pop-img {
  border-radius: 50%;
  max-width: 200px;
  max-height: 200px;
  text-align: center;
  margin: auto;
  background-color: gray;
}



/*Search*/
/*Group*/

.CWI {
  padding: 10px 0 0 10px;
}

.cwoi {
  padding: 10px 0 10px 10px;
}

.CIOLC-wrapper {
  border-radius: 10px;
  -webkit-filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.16));
          filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.16));
}

.CIOLC-img {
  border-radius: 10px;
  width: 100%;
}

.HSWLTL-title {
  padding: 10px;
  padding-top: 0;
}

.I_W2T_M_C-img {
  height: 100px;
  max-width: 100%;
}

.I_W2T_M_C-wrapper {
  background: #FFFFFF;
  border: 1px solid #EFEEEE;
  box-sizing: border-box;
  box-shadow: 2px 2px 6px rgba(154, 153, 153, 0.2);
  border-radius: 4px;
  text-align: center;
}

.I_W2T_M_C-text-wrapper {
  padding: 5px;
}

.I_W2T_B_M_C-wrapper {
  text-align: center;
  box-shadow: 2px 2px 6px rgba(154, 153, 153, 0.2);
  border-radius: 2px;
  padding-bottom: 5px;
}

.I_W2T_B_M_C-img {
  height: 100px;
  max-width: 100%;
}

.IWSTLFC-wrapper {
  text-align: center;
  box-shadow: 2px 2px 6px rgba(154, 153, 153, 0.2);
  border-radius: 2px;
  padding-bottom: 5px;
}

.IWSTLFC-img {
  width: 100%;
}

.card-text {
  /*display: -webkit-box;*/
  display: grid;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 30px;
  overflow: hidden;
  padding: 0!important;
  font-size: 11px;
  padding: 0 5px!important;
}

.riwtmc-wrapper {
  text-align: center; 
  border: 1px solid #EEEEEE;
  background-color: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 2px 2px 6px rgba(154, 153, 153, 0.2);
  border-radius: 4px;
  padding-bottom: 5px;
  font-size: 13px;
}


.card5-img-box {
  height: 80px;
  padding-top: 12px;
  text-align: center
}

.card5-img {
  border-radius: 50%;
  height: 56px;
  width: 56px;
}

.login {
  padding: 15px;
  align-self: center;
  text-align: center;
  /*margin: 0px auto;*/
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  /*position: relative;*/
  width: 50%;
  box-shadow: 2px 2px 6px rgba(154, 153, 153, 0.2);
}

.form-input {
  padding: 15px;
  align-self: left;
  text-align: left;
  margin: 10px auto;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  /*position: relative;*/
  width: 50%;
  box-shadow: 2px 2px 6px rgba(154, 153, 153, 0.2);
}



.testi{
  text-align: center;
  margin: 0% 0px;
}

.address-container{
  padding: 10px 20px;

}

.btn-jt-green {
  background-color: #58a159;
  color: #fff;
  outline: none;
  margin: 5% 0px;
  align-self: center;
  text-align: center;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  height: 36px;
  width: 126px;
  text-align: center;
}

a {
  text-decoration: none;
  color: unset;
}

.iobc-img {
  -webkit-filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.16));
          filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.16));
  border-radius: 4px;
  width: 100%;
}

.group-left-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #000000;
  padding: 0 15px;
}

.group {
  margin: 10px 0;
}

.gw3c-children {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill,minmax(25%,1fr));
  padding: 10px;
}

.selected-quantity-input {
  height: 32px;
  width: 42px;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;

  /* or 18px */
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 6px;

  color: #000000;
}

.selected-quantity-button {
  height: 32px;
  width: 32px;
  border: 1px solid #000000;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 2px;
  margin-left: 6px;
}

.fwcwi-children {
  padding: 0 10px;
}
.iobc-wrapper {
  cursor: pointer;
}



.vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.payment-header {
    color: #8F8F8F;
    font-size: 18px;
}

.payment-details-box {
    background-color: #F0F0F0;
    border-radius: 4px;
    padding: 9px 12px;
}

.payment-itm {
    color: #000000;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 10px;
}

.payment-itm:last-child {
  margin-bottom: 0;
}

.bigg {
    font-weight: 600;
}

.delivery-notes {
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  padding: 8px 15px;
  outline: none;
}

.padding-twenty {
  padding: 20px;
}

.top-twenty {
  margin-top: 20px;
}

.top-fifty {
  margin-top: 50px;
}

.top-six {
  margin-top: 6px;
}

.right-text {
  text-align: right;
}

.no-right-pad {
  padding-right: 0;
}

.centered {
  text-align: center;
}

.lr-10 {
  padding: 0 10px;
}
.lr-20 {
  padding: 0 20px;
}

.swiper-pagination-bullet-active {
  background: #62B04D!important;
}

/*.row {
  display: flex;
  width: 100%;
}

.col-1 {
  width:12%;
  float:left;
  position:relative
}

.col-2 {
  width:12%;
  float:left;
  position:relative
}

.col-3 {
  width:12%;
  float:left;
  position:relative
}

.col-4 {
  width:12%;
  float:left;
  position:relative
}

.col-5 {
  width:12%;
  float:left;
  position:relative
}

.col-6 {
  width:12%;
  float:left;
  position:relative
}

.col-7 {
  width:12%;
  float:left;
  position:relative
}

.col-8 {
  width:12%;
  float:left;
  position:relative
}

.col-9 {
  width:12%;
  float:left;
  position:relative
}

.col-12 {
  width:12%;
  float:left;
  position:relative
}
*/
.login {
  padding: 15px;
  align-self: center;
  text-align: center;
  /*margin: 0px auto;*/
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  /*position: relative;*/
  width: 50%;
  box-shadow: 2px 2px 6px rgba(154, 153, 153, 0.2);
}

.testi{
  text-align: center;
  margin: 15% 0px;
}

.btn-jt-green {
  background-color: #58a159;
  color: #fff;
  outline: none;
  margin: 5% 0px;
  align-self: center;
  text-align: center;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  height: 36px;
  width: 126px;
  text-align: center;
}


/*img {
  width: 100%;
}*/


.address-header{
    height: 40px;
    width: 100%;
    background-color: gray;
    display: flex;
    flex-direction: row;
    padding: 2%;
}

.in-mob-box {
  border: none;
  border-bottom: 1px solid #cccccc;
  border-radius: 2px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0px;
  padding: 10px 0;
  width: 100%;
  outline: none;
  background-color: #fff;
}


.fl-lbl {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  transform: translate(0px, 0px);
  transform-origin: top;

}

.fl-lbl-up {
  position: absolute;
  top: 0px;
  left: 0;
  padding: 0;
  background-color: #fff;
  transform: translate(0, -8px);
  transform-origin: center;  
  font-size: 12px!important;  
}

.entered {
  color: #62B04D!important;
}

.entered-box {
  border-color: #62B04D!important;
}

.lll {
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  color: #808080;
  font-size: 15px;
  font-weight: normal;
}

.top30{
  margin-top: 30px;
}

.input-login{
  text-align: center;
  align-self: center;
  margin-left: 25%;
  margin-right:25%;
  margin-top: 25%;
  width:50%;
}

.btn-login{
  background-color: #62B04D;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: bold;
  padding: 15px 40px;
  outline: none;
  border-radius: 4px;
  border: none;
}

.otp-box {
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-size: 20px;
  line-height: 24px;
  padding: 11px;
  width: 48px;
  outline: none;
  text-align: center;
  margin-right: 15px;
}

.otp-box:last-child {
  margin-right: 0;
}

.otp-lbl {
  font-size: 18px;
  line-height: 150%;
  color: #000000;
}

.btn-add-address{
  background-color: white;
  padding: 15px;
  outline: none;
  width: 100%;
  border: 1px solid black;
  border-radius: 4px;

}

.pad-left-15 {
  padding-left: 15px;
}

.row, .row-reverse {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.row-reverse {
  flex-direction: row-reverse;
}

.col-1 {
  flex: 0 0 8.33%;
  max-width: 8.33%;
  position: relative;
  width: 100%;
}

.col-2 {
  flex: 0 0 16.66%;
  max-width: 16.66%;
  position: relative;
  width: 100%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  width: 100%;
}

.col-4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  position: relative;
  width: 100%;
}

.col-5 {
  flex: 0 0 41.66%;
  max-width: 41.66%;
  position: relative;
  width: 100%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
}

.col-7 {
  flex: 0 0 58.33%;
  max-width: 58.33%;
  position: relative;
  width: 100%;
}

.col-8 {
  flex: 0 0 66.66%;
  max-width: 66.66%;
  position: relative;
  width: 100%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
  position: relative;
  width: 100%;
}

.col-10 {
  flex: 0 0 83.33%;
  max-width: 83.33%;
  position: relative;
  width: 100%;
}

.col-11 {
  flex: 0 0 91.66%;
  max-width: 91.66%;
  position: relative;
  width: 100%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
}

.bt-mar-55 {
  margin-bottom: 55px
}

.left-5 {
  margin-left: 5px;
}
.left-10 {
  margin-left: 10px;
}

a {
  text-decoration: none;
}

.dotted-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.full-width{
  width: 100%;
}

button {
  outline: none;
  cursor: pointer;
}

input {
  outline: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.flex {display: flex;}
.relative { position: relative; }
.absolute { position: absolute; }
.justify-content-between { justify-content: space-between; }
.col {display: flex; flex-direction: column;}
.row.center, .col.center {
  justify-content: center;
  align-items: center;
}
.absolute.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.align-center{ align-items: center;}

.lg-view{
  display:none;
}
.sm-view{
  display:inline-block;
}
.px-10 { padding: 0 10px; }
.py-10 { padding: 10px 0; }
.trunc-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn:hover {
  opacity: 0.9;
}

.side-button {
  height: 88px;
  width: 44px;
  background-color: #FFFFFF;
  border: 2px solid #EEEEEE;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  text-align: center;
  line-height: 92px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  cursor: pointer;
}

.left-bx {
  left: -8.33%;
  position: absolute;
  height: 100%;
}

.right-bx {
  right: calc(-8.33% + 48px);
  position: absolute;
  height: 100%;
}

.non-usable {
  opacity: 0.4;
}

@media screen and (min-width: 576px) {
  .lg-view{
    display:inline-block;
  }
  .sm-view{
    display:none;
  }

  .group {
    margin: 20px 0;
  }
  .gw3c-children {
    grid-template-columns: repeat(auto-fill,minmax(150px,1fr));
    justify-content: center;
    grid-gap: 20px;
  }
  .card-text {
    font-size: 13px;
    height: 40px;
  }
  .HSWLTL-children .IWSTLFC-img {
    width: 250px;
    height: 150px;
    object-fit: cover;
  }

  .HSWLTL-children .swiper-slide {
    width: auto  !important;
  }

  .store-label-img {
    height: 100px;
  }

  .store-cover {
    height: 365px;
  }
  
}

@media screen and (min-width: 768px) {
 .uinode {
    width: 600px;
    margin: auto;
  }

  .tab {
    margin-left: calc(-100vw / 2 + 600px / 2);
    margin-right: calc(-100vw / 2 + 600px / 2);
  }
}

@media screen and (min-width: 900px) {
 .uinode {
    width: 700px;
    margin: auto;
  }

  .tab {
    margin-left: calc(-100vw / 2 + 700px / 2)!important;
    margin-right: calc(-100vw / 2 + 700px / 2)!important;
  }
}

@media screen and (min-width: 1000px) {
 .uinode {
    width: 800px;
    margin: auto;
  }

  .tab {
    margin-left: calc(-100vw / 2 + 800px / 2)!important;
    margin-right: calc(-100vw / 2 + 800px / 2)!important;
  }
}

@media screen and (min-width: 1200px) {
 .uinode {
    width: 900px;
    margin: auto;
  }

  .tab {
    margin-left: calc(-100vw / 2 + 900px / 2)!important;
    margin-right: calc(-100vw / 2 + 900px / 2)!important;
  }
}

@media screen and (min-width: 1400px) {
 .uinode {
    width: 1000px;
    margin: auto;
  }

  .tab {
    margin-left: calc(-100vw / 2 + 1000px / 2)!important;
    margin-right: calc(-100vw / 2 + 1000px / 2)!important;
  }
}

@media screen and (min-width: 1600px) {
 .uinode {
    width: 1300px;
    margin: auto;
  }

  .tab {
    margin-left: calc(-100vw / 2 + 1300px / 2)!important;
    margin-right: calc(-100vw / 2 + 1300px / 2)!important;
  }
}

/* cradit */

/* progress bar */
.back-bar:before {
  display: block;
  content: "";
  margin-top: 0;
  height: 10px;
  width: 100%;
  background-color: #fff0d0;
  background-size: 10px 10px!important;
  background-position: 0 -23px;
}
.inner-bar {
  background-color: #ffc83c;
  height: 10px;
  margin-top: -10px;
  border-radius: 2px;
  width: 0;
  transition: width .6s ease;
}
.unlock-credit .large-text,
.title-text {
  color: #303030;
  font-size: 17px;
  font-weight: 600;
}

.unlock-credit .title-text {
  font-size: 18px;
}

.unlock-credit {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  margin-bottom: 45px;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
}
.hta {
  text-align: center;
  font-weight: 500;
}

.hta-img-wrapper {
  position: relative;
}

.hta-item-num {
  position: absolute;
  top: 0;
  background: #fce27f;
  font-size: 12px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.hta-items {
  display: flex;
  padding: 30px 10px;
  margin: auto;
  justify-content: center;
}

.hta .title-text {
  position: relative;
}

.hta .title-text::after {
  content: "";
  position: absolute;
  display: block;
  width: 20%;
  height: 2px;
  background: black;
  left: 50%;
  transform: translate(-50%, 5px);
}

.hta-item {
  position: relative;
  max-width: 80px;
}

.hta-text {
  font-size: 14px;
  font-weight: 500;
}

.hta-img {
  width: 25px;
}

.hta-img.arrow {
  margin: 0 10px;
}

.apply-now-cta,
.apply-now-cta-gray {
  padding: 15px;
  color: #fff;
  background: #68b569;
  letter-spacing: 1.2px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  position: fixed !important;
  bottom: 0 !important;
  font-weight: 700;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.apply-now-cta-gray {
  background: gray;
  cursor: not-allowed;
  pointer-events: none;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.benefit + .benefit {
  margin-top: 5px;
}

.unlock-credit * {
  box-sizing: border-box;
}

.unlock-credit .small-text {
  font-weight: 500;
  font-size: 14px;
  color: gray;
  line-height: 16px;
}

.unlock-credit-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.unlock-credit .banner-img {
  width: 100%;
}

.unlock-credit-text {
  position: absolute;
  background: #f4f4f4;
  padding: 10px;
  bottom: -22px;
  letter-spacing: 1.2px;
  text-align: center;
  width: 100%;
}

.unlock-credit .benefits {
  padding: 20px 10px;
  margin: 15px 0 20px 0;
}

.unlock-credit .benefit {
  display: flex;
  padding: 10px 5px;
}
.hta .title-text {
  position: relative;
}
.delayed-grp-ctas .pay-now {
  font-family: "Roboto", sans-serif;
  padding: 8px 16px;
  color: #fff;
  background: #68b569;
  letter-spacing: 1.2px;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  border-radius: 3px;
}
.delayed-grp-ctas .view-details {
  font-family: "Roboto", sans-serif;
  padding: 6px 6px;
  color: #303030;
  border: 1px solid #303030;
  letter-spacing: 1.2px;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  border-radius: 3px;
}
.store-banner {
  width: 100%;
}

/*.store-selector-wrapper {
  margin-top: 10px;
}
*/

.med-text {
  font-weight: 500;
}

.store-search-box {
  position: absolute;
  bottom: 30px;
  width: 100%;
  max-width: 800px;
  left: calc(50% - 400px);
  text-align: center;
}

.no-store-box {
  background-color: #EFEFEF;
  text-align: center;
  padding: 21px;
  font-size: 16px;
  line-height: 30px;
  margin: 20px 15px;
}

.store-selector-header {
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
  width: 100%;
}

.store-wrapper {
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: 15px;
}

.store-name {
  font-size: 16px;
  font-weight: 500;
}

.store-address {
  color: gray;
  font-size: 13px;
  margin-top: 5px;
}

.store-closed {
  text-align: center;
  color: red;
  font-size: 12px;
  padding: 2px 10px;
  width: 50px;
  border-radius: 4px;
  border: 1px solid red;
  display: inline-block;
}

.store-open {
  text-align: center;
  color: green;
  font-size: 12px;
  padding: 2px 10px;
  width: 50px;
  border-radius: 4px;
  border: 1px solid green;
  display: inline-block;
}

.distance-indicator {
    display: inline-block;
    padding-left: 13px;
    line-height: 22px;
    font-size: 14px;
    color: #8F8F8F;
}

.store-info-wrapper {
  background-color: #62B04D;
  padding: 0px 50px 40px 50px;
  color: #fff;
}

.store-info-name {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}

.store-s-address {
  font-size: 13px;
  line-height: 17px;
}

.store-list {
  margin: 20px 50px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(auto, 450px));
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.store-cover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(98, 176, 77, 0.2);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  margin-top: -84px;
}

.cover-overlay {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background-color: rgba(98, 176, 77, 0.3);
   box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2)
}

@media(max-width: 500px) {
  .store-list{
    grid-gap: 15px;
    gap: 15px;
    margin: 20px 15px;
  }

  .store-search-box {
    position: absolute;
    bottom: 20px;
    width: 100%;
    max-width: 800px;
    left: 0;
    text-align: center;
}
}
.rec-dropdown-new {
  width: 100%;
  border: none;
  border-radius: 0px;
 /* box-shadow: 0 4px 8px 2px rgba(160, 160, 160, 0.5);*/
  padding: 0;
  border: 1px solid #dfdfdf;
  margin-top: 0;
}

.dropdown-data-rec {
  border-bottom: 2px solid #c5c5c5;
  padding: 3px 0;
  list-style: none;
}

.dropdown-data-rec-inner {
  padding: 12px 10px;
}

.dropdown-data-rec-inner:hover {
  background-color: #f2f2f2;
}

.dropdown-data-rec:last-child {
  border-bottom: none;
}

.no-jobs-to-assign {
  text-align: center;
  font-size: 16px;
  margin-top: 20%;
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 6px;
  box-sizing: border-box;
  border: 26px solid #000;
  border-color: #000 transparent #000 transparent;
  -webkit-animation: lds-hourglass 1.2s infinite;
          animation: lds-hourglass 1.2s infinite;
}
@-webkit-keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}

.loader-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}
  
.loader-box {
  margin: auto;
  background-color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  box-shadow: 0 1px 6px 0 #8e8e8e;
  left: 0;
  right: 0;
}

.loader {
  border: 5px solid #dfdfdf;
  border-top: 5px solid #62B04D;
  border-radius: 50%;
  width: 50%;
  height: 50%;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  margin: 10px;
}

@-webkit-keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-box {
  width: 100%;
  text-align: center;
}

.error-text {
  color: #777777;
  padding: 20px 10px 0 10px;
  font-size: 16px;
}

.error-gohome-btn {
  background-color: #fff!important;
  color: #000!important;
  border-radius: 0px!important;
  width: 162px!important;
  height: 42px!important;
  font-size: 14px!important;
}

.error-retry-btn {
  background-color: #000!important;
  color: #fff!important;
  border-radius: 0px!important;
  width: 162px!important;
  height: 42px!important;
  font-size: 14px!important;
}


@media(min-width: 600px) {  

}
.product-list {
  padding: 5px 10px;
}

/*.product-card {
  width: 100%;
  padding: 5px;
  height: 115px;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  margin-bottom: 15px;
}

.product-card-img {
  height: 100px;
  width: 100px;
  padding: 5px;
}

.product-card-details {
  height: 100%;
  padding-left: 10px;
  text-align: left;
  flex-grow: 1;
  display: flex;
  border-radius: 0 7px 10px 7px;
}

.product-card-img img {
  height: 100px;
  width: 100px;
}

.product-card-text {
  width: 100%;
  flex-grow: 1;
}

.product-card-text h1 {
  font-size: 14px;
  float: left;
  width: 100%;
  font-weight: 500;
}

.product-card-btn {
  align-self: flex-end;
  position: relative;
  margin-bottom: 5px;
  margin-right: 5px;
}

.add-with-quantity {
  display: flex;
  height: 32px;
}

.add-with-quantity input {
  width: 30px;
  border: 1px solid #62B04D;
  margin: 0 5px;
  text-align: center;
}

.add-with-quantity button {
  width: 30px;
  background-color: #fff;
  border: 1px solid #62B04D;
  color: #62B04D;
}

button.add {
  box-sizing: border-box;
  color: #00a84f;
  padding: 0px 10px;
  text-transform: uppercase;
  border: 1px solid #62B04D;
}*/

.search-display-box {
  padding: 5px 20px;
  line-height: 18px;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  position: relative;
  box-shadow: 0 1px 2px 0px rgba(128, 128, 128, 0.5);
  color: gray;
}

.search-display-wrapper {
  position: absolute;
  bottom: 20px;
  padding: 10px;
  z-index: 20;
  width: calc(100% - 52px);
}

.search-box-wrapper {
  padding: 10px 20px 15px 20px;
}

.search-box {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dfdfdf;
  box-shadow: 0 1px 2px 1px rgba(128, 128, 128, 0.5);
  padding: 5px 10px 5px 15px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-box-mobile {
  justify-content: start;
}
.search-box-mobile .back {
  padding-right: 10px;
  cursor: pointer;
}

.search-text {
  padding: 0;
  width: 90%;
  height: 24px;
  line-height: 24px;
  font-size: 16px;
  border:none;
  outline: none;
}

.search-box-desktop .search-suggestions {
  position: absolute;
  z-index: 100;
  max-width: 800px;
  left: 0;
  right: 0;
  top: 42px;
  background: #FFFFFF;
  box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  color: black;
  font-size: 15px;
  max-height: 400px;
  overflow: auto;
}
.search-box-desktop .rec-dropdown-new {
  border: none;
  margin: 0;
}

.search-box-desktop .dropdown-data-rec {
  border: none;
}

.dropdown-data-rec + .dropdown-data-rec  {
  border-top: 1px solid #c5c5c5;
}

.spinner {
  border: 3px solid lightgray; /* Light grey */
  border-top: 3px solid #62b04d; /* Blue */
  border-radius: 50%;
  width: 14px;
  height: 14px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.discount-banner {
  background-color: #ffdc8d;
  width: 60%;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 12px;
  margin-right: 12px;
  padding: 3px;
}

.product-wrapper {
  margin: 20px;
}

.product-img {
  max-width: 100%;
  max-height: 144px;
  width: auto;
  height: auto;
}

.product-title {
  padding-bottom: 16px;
  font-weight: 400;
  /* font-size: 16px; */
}

.prod-desc-wrapper {
  margin-top: 20px;
  border-top: 1px solid #dfdfdf;
  padding-top: 20px;
}

.product-desc {
  font-size: 12px;
  font-weight: 400;
}

.pricecut-price {
  font-size: 16px;
  line-height: 32px;
  color: #8f8f8f;
  text-decoration: line-through;
  margin-right: 10px;
}

.product-price {
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
}

.card-img {
  max-width: 100%;
  max-height: 75px;
  padding-left: 12px;
}

.product-list {
  padding: 5px 10px;
}

.product-card {
  padding: 12px 12px 12px 0px;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  box-sizing: border-box;
  /*display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;*/
  margin-bottom: 15px;
}

.product-card-img {
  height: 100px;
  width: 100px;
}

.product-card-details {
  height: 100%;
  padding-left: 10px;
  text-align: left;
  flex-grow: 1;
  display: flex;
  border-radius: 0 7px 10px 7px;
}

.product-card-img img {
  max-height: 100px;
  max-width: 100px;
}

.product-card-text {
  width: 100%;
}

.product-card-text h1 {
  font-size: 14px;
  width: 100%;
  color: #111111;
  font-weight: 500;
}

.product-card-btn {
  position: relative;
  margin-bottom: 5px;
  margin-right: 5px;
}

.add-with-quantity {
  display: inline-block;
  position: relative;
}

.add-with-quantity input {
  width: 30px;
  border: 1px solid #dfdfdf;
  margin: 0 5px;
  text-align: center;
  border-radius: 2px;
  height: 28px;
}

.add-with-quantity button {
  width: 30px;
  background-color: #fff;
  border: 1px solid #62b04d;
  color: #62b04d;
  border-radius: 2px;
  outline: none;
  height: 32px;
}

.no-ivt {
  border-color: #dfdfdf !important;
  color: #dfdfdf !important;
}

button.add {
  box-sizing: border-box;
  color: #62b04d;
  padding: 3px 15px;
  background-color: #fff;
  border-radius: 16px;
  text-transform: uppercase;
  border: 1px solid #62b04d;
  height: 32px;
}

.sold-out {
  font-weight: bold;
  color: #f44336;
}

.dropdown-prices {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dropdown-prices .pricecut-price {
  margin: 0;
}

@media (min-width: 768px) {
  .product-wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 50px;
    grid-gap: 10px;
    gap: 10px;
  }
  .product-wrapper .product-title {
    font-size: 20px;
  }
  .product-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    grid-column-gap: 12px;
    -webkit-column-gap: 12px;
            column-gap: 12px;
  }

  .product-img {
    max-width: 100%;
    max-height: 200px;
    width: auto;
    height: auto;
  }
  .price-qty .right-text {
    text-align: unset;
  }
}

.tab {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 10px;
  border-bottom: 1px solid #dfdfdf;
  position: -webkit-sticky;
  position: sticky;
  top: 55px;
  z-index: 99;
  background-color: #efefef;
  margin: 0 -20px;
}

.tab-option {
  padding: 5px;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 40px;
  color: #8f8f8f;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin: 10px 20px;
}

.tab-option:hover {
  cursor: pointer;
}

.tab-option.active {
  border-color: #62b04d;
  font-weight: 600;
  color: #62b04d;
}

.swiper-container {
  width: 100% !important;
  box-sizing: border-box;
}

@media screen and (max-width: 576px) {
  .tab {
    margin: 0;
  }

  .tab-option {
    margin: 10px 5px;
  }
}

.login-form {
  max-width: 300px;
  margin: auto;
  text-align: center;
  padding: 0 20px;
}
.guest-checkout {
  color: #62B04D;
  font-weight: 700;
  font-size: 16px;
}
.sales-invoice-row {
  margin: 10px;
  border: solid 1px #dfdfdf;
  border-radius: 4px;
  padding: 10px;
}
.selected-order {
  border: 2px solid #62b04d;
}

.no-orders-made {
  font-size: 24px;
  margin: 10px;
}

.pagination {
  display: inline-block;
  list-style-type: none;
  width: 100%;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}

.page-item {
  float: left;
  margin-right: 10px;
  padding: 8px 16px;
}

.page-item.active {
  background-color: #62b04d;
}

.page-link:hover {
  cursor: pointer;
}
/*.row-with-wrap {
  display: flex;
  flex-wrap: wrap;
}

.row {
  display: flex;
}

.jt-col-33 {
  width:33%;
  float:left;
  position:relative
}*/

.div-inline {
  display: inline-block !important;
}

.sales-invoice-detail-row {
  margin: 10px 0;
  padding: 10px;
  border: solid 1px #dfdfdf;
  border-radius: 4px;
}

.sales-invoice-row:hover {
  cursor: pointer;
}

.sales-invoice-detail-row .sales-invoice-img {
  max-height: 60px;
  max-width: 60px;
  min-height: 40px;
  min-width: 40px;
}

.sales-invoice-detail-row .sales-invoice-img-div {
  margin-right: 10px;
}

.sales-invoice-items-list-wrapper {
  overflow: auto;
}
.sales-invoice-items-list-wrapper .header,
.bill-hheader {
  margin: 10px 0;
  font-size: 16px;
  color: #8f8f8f;
}

.sales-invoice-detail-row .header-text-div {
  font-size: 14px;
  color: #111111;
  margin: 5px 0 10px 0;
}

.sales-invoice-detail-row .sub-header {
  color: #8f8f8f;
  font-size: 11px;
  margin-bottom: 5px;
}
.sales-invoice-detail-row .sub-value {
  color: #111111;
  font-size: 11px;
}

.bill-detail-div-wrapper .sales-invoice-item-div {
  margin: 5px;
}

.bill-detail-div-wrapper {
  padding: 10px 30px;
  background-color: #f2f2f2;
  line-height: 35px;
  border-radius: 4px;
}

.bill-detail-div-wrapper .hheader {
  font-size: 14px;
  color: #8f8f8f;
}

.bill-detail-div-wrapper .vvalue {
  font-size: 14px;
  color: #111111;
}

.j24-sales-invoice-details-wrapper {
  padding: 16px 18px;
  background-color: #ffffff;
}

.sales-invoice-items-list-wrapper {
  overflow: auto;
}

.sales-invoice-items-list-wrapper .hheader {
  margin: 10px 0;
  font-size: 16px;
  color: #8f8f8f;
}

.post-order {
  margin-top: -56px;
  position: relative;
  z-index: 209;
  background-color: #fff;
  padding-top: 40px;
}

.post-up-wrapper {
  padding: 34px;
  max-width: 500px;
  margin: auto;
}

.cong-text {
  color: #559943;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  font-weight: bold;
}

.del-text {
  color: #559943;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  background: #f0f0f0;
  padding: 5px 20px;
  border-radius: 4px;
}
.del-tmr {
  background: rgba(85, 153, 67, 0.12);
}

.ord-info {
  color: #111111;
  font-size: 16px;
  line-height: 28px;
  font-weight: 200;
  padding: 10px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
}

.bottom-go-home {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.gm-bt {
  padding: 24px;
  text-align: center;
}

.go-hm-btn {
  background-color: #fff;
  border: 2px solid #62b04d;
  border-radius: 4px;
  color: #62b04d;
  padding: 11px 20px;
  font-size: 16px;
  font-weight: bold;
}

.my-orders-header {
  font-size: 16px;
  color: #8f8f8f;
  margin: 20px;
  margin-bottom: 0px;
}

.my-orders {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 0 10px;
  /* padding: 16px 18px; */
}
.my-orders .j24-sales-invoice-details-wrapper {
  padding: 10px;
}
.orders-left,
.selected-order-right {
  height: calc(100vh - 55px);
  overflow: scroll;
}
@media (min-width: 768px) {
  .bottom-go-home {
    position: relative;
  }
}

.orders-left::after {
  content: "";
  height: calc(90vh - 60px);
  width: 1px;
  background-color: lightgray;
  position: absolute;
  top: 55px;
  left: 50%;
  margin: calc(55px - 1%) 0;
}

.address-wrapper, .suggestions-wrapper {
	padding: 15px;
	box-sizing: border-box;
	position: relative;
}
.address-wrapper + .address-wrapper {
	border-top: 1px solid #DFDFDF;
}
.address-wrapper:nth-child(1) {
	border-top: 1px solid #DFDFDF;
}

.suggestions-wrapper + .suggestions-wrapper {
	border-top: 1px solid #DFDFDF;
}
.suggestions-wrapper:nth-child(1) {
	border-top: 1px solid #DFDFDF;
}
.suggestions-wrapper:last-child {
	border-bottom: 1px solid #DFDFDF;
}

.selected-addr {
	background-color: #F5F5F5;
}
.check { display: none;}
.selected-addr .check, .quick-address .check{
	display: block;
	padding: 0 5px;
}

.location-error {
	position: absolute;
	color: white;
	font-size: 18px;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 20px;
	box-sizing: border-box;
	text-align: center;
}

.customer-main-info {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 150%;
	color: #000000;
}

.address-attr {
	font-size: 14px;
	line-height: 150%;
	color: #2b2b2b;
}

.new-address {
	height: calc(100vh - 55px);
	width: 100vw;
	display: flex;
	flex-direction: column;
}

.new-address-btn {
	background: #FFFFFF;
	border: 1px solid #DFDFDF;
	box-sizing: border-box;
	box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12);
	border-radius: 4px;
	padding: 12px 20px;
	color: #8F8F8F;
	font-size: 16px;
}

.confirm-btn-box {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
}

.btm-btn-wrapper {
	padding: 20px;
	display: flex;
	justify-content: center;
}

.confirm-order {
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	color: #FFFFFF;
	padding: 16px;
	text-align: center;
	background: #62B04D;
	box-shadow: 0px -2px 6px rgba(176, 176, 176, 0.512596);
	border-radius: 4px;
	width: 100%;
	border: none;
}

.btn-address-place {
		font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 16px;
    text-align: center;
    background: #62B04D;
    box-shadow: 0px -2px 6px rgba(176, 176, 176, 0.512596);
    border-radius: 4px;
    width: 100%;
    border: none;
}

.s-error-text {
	font-size: 12px;
	color: red;
}

.quick-address {
	box-shadow : 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
	position: fixed;
	bottom: 55px;
	left: 0;
	right: 0;
	min-height: 55px;
	background-color: #ffffff;
	z-index: 3;
	padding: 10px 20px;
}
.delivers-to { 
	font-weight: 500;
	color: gray; 
	font-size: 14px;
}
.change-addr,.address-name {
	font-weight: 500;
}
.address-name {
	padding: 5px 0;
}
.change-addr{
	color: #62B04D;
	cursor: pointer;
}
.address-line {
	font-size: 14px;
}
.address-list-wrapper {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 100;
}
.address-list-inner {
	box-shadow : 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
	position: fixed;
	bottom: 0px;
	left: 0;
	right: 0;
	max-height: 50%;
	min-height: 200px;
	background-color: #ffffff;
	z-index: 5;
	overflow: scroll;
}

.address-list-header {
	padding: 15px;
	font-size: 17px;
	color: gray;
}
.add-address {
	font-weight: 500;
	color: #62B04D;
}

.delivery-info-card {
	box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.16);
	position: relative;
	border: 1px solid transparent;
	background-color: white;
	margin-bottom: 70px;
	max-height: 50vh;
	overflow: scroll;
}

.location-row {
	display: flex;
	justify-content: space-between;
	padding: 20px 4.5%;
	padding-bottom: 0;
}
.current-location {
	font-size: 14px;
	padding: 5px;
}
.search-address {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 100;
	background: #fff;
	overflow: hidden;
}
.address-container .top30 {
	margin: 0;
	padding-bottom: 15px;
}

.change-address-btn {
	outline: none;
  cursor: pointer;
  border: 1.5px solid #62B04D;
	box-sizing: border-box;
  border-radius: 4px;
  background: inherit;
  color: #62B04D;
  padding: 5px 10px;
  font-size: 14px;
}

.search-address .close {
	position: absolute;
	top: 15px;
	right: 20px;
	background: white;
}

.search-address-input {
	width: 100%;
	padding: 15px;
	font-family: "DM Sans";
	font-size: 16px;
	border: none;
	outline: none;
	border-bottom: 1px solid #ccc;
}

.pick-cur-loc {
	color: #62B04D;
	font-weight: 500;
	display: flex;
	align-items: center;
}

#map {
	flex-grow: 1;
}

@media(min-width: 768px) {
	.quick-address {
		position: relative;
		bottom: 0;
		box-shadow: none;
		margin-top: 20px;
		border: 1px solid #ccc;
		border-radius: 4px;
	}
	.quick-address .trunc-text-overflow{
		overflow: auto;
		text-overflow:inherit;
		white-space:inherit;
	}
	.address-container {
		display: grid;
		grid-template-columns: 48% 48%;
		grid-gap: 3%;
		gap: 3%;
		margin: 0 3% ;
	}
	.address-container * {
		box-sizing: border-box;
	}
	.btn-address-place {
		width: calc(2*48% + 3% - 2*20px);
		display: flex;
		justify-content: center;
	}

}
.bottom-cart {
	box-shadow : 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	min-height: 55px;
	background-color: #ffffff;
	z-index: 3;
}

.cart-inner {
	padding: 4px 6px;
}

.pad-12 {
	padding: 12px;
}

.cart-btn {
	background-color: #62B04D;
	border-radius: 2px;
	height: 47px;
	font-size: 14px;
	line-height: 47px;
	text-align: center;
	width: 100%;
	color: #ffffff;
	border: none;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
}

.cart-btn.place-order {
	margin: 20px 0;
	font-size: 16px;
	font-weight: 500;
}

.c-items-indic {
	background-color: #62B04D;
    color: #fff;
    font-weight: 500;
    line-height: 22px;
    position: absolute;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    font-size: 12px;
    left: 35%;
}

.no-item-box {
	padding: 20%;
	text-align: center; 
	font-size: 20px;
}

.bottom-cart-icon {
	vertical-align: bottom;
	margin-bottom: 3px;
}

.b-cart-amount {
	font-size: 17px;
    line-height: 20px;
    margin-top: 8px;
    font-weight: 500;
}

.b-cart-items {
	font-size: 14px;
    line-height: 16px;
    opacity: 0.4;
}

.go-home-btn {
	background-color: #62B04D!important;
    color: #fff!important;
    border-radius: 0px!important;
    padding: 10px 20px;
    font-size: 18px;
    border: none;
    border-radius: 2px!important;
    outline: none;
}

.accck {
	background-color: #fff;
    z-index: 101;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    border-radius: 4px;
    padding: 30px;
    text-align: center;
}

.alrt-pr-img {
	max-height: 150px;
	max-width: 100%;
}

.alrt-title {
	font-weight: 600;
	font-size: 18px;
	color: #d55d3b;
}

.alrt-msg {
	opacity: 0.6;
	font-weight: 500;
}

.accept-itm {
	border: 1px solid #d55d3b;
	color: #d55d3b;
	background-color: #fff;
	border-radius: 20px;
	padding: 7px 10px;
	font-size: 18px;
	width: 130px;
	font-weight: 500;
}

.remove-itm {
	border: 1px solid #d55d3b;
	color: #fff;
	background-color: #d55d3b;
	border-radius: 20px;
	padding: 7px 10px;
	font-size: 18px;
	width: 130px;
	font-weight: 500;
}

.place-order {
	display: none;
}

.cart-dropdown {
	position: absolute;
	z-index: 101;
	color: black;
	line-height: normal;
	background: white;
	border: 1px solid #DFDFDF;
	cursor: default;
	box-shadow: rgba(0,0,0, 0.2) 0px 1px 3px;
	right: 0;
	min-width: 450px;
}
.cart-dropdown-inner {
	width: 460px;
	max-height: 400px;
	overflow: scroll;
}
.cart-dropdown:before {
	position:absolute;
	top:-10px; right:30px;
	content: '';
	width: 0;
	height: 0;
	border-left: 10px solid transparent;
	border-right: 10px solid transparent;
	border-bottom: 10px solid white;
	z-index:2;
}
.open-cart, .close-cart {
	cursor: pointer;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}
.open-cart {
	background-color: rgba(0, 0, 0, 0.25);
	border-radius: 5px;
}
.cart-dropdown:after {
	position:absolute;
	top:-12px; right:8px;
	content: '';
	width: 0;
	height: 0;
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-bottom: 12px solid rgba(0,0,0, 0.03);
	z-index:1;
}
.cart-dropdown .product-card + .product-card {
	border-top: 1px solid #ccc !important;
}

.empty-cart {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80%;
}
.page-wrapper {
	overflow: scroll;
}

@media(min-width: 768px) {	
	.place-order {
		display: block;
	}
	.cart-page {
		display: grid;
		grid-template-columns: minmax(460px, 1fr) minmax(auto, 400px);
		grid-gap: 2%;
		gap: 2%;
		padding: 20px 4%;
	}
	.cart-page-right { 
		padding-top: 20px;
		grid-row: 1;
		grid-column: 2;
	} 

}
.side-drawer { 
   height: 100%;
   background: white;
   position: fixed;
   top: 0;
   left: 0;
   width: 50%;
   z-index: 200;
   box-shadow: 1px 0px 7px rgba(0,0,0,0.5); 
   transform: translateX(-110%);
   transition: transform 0.3s ease-out;
}

.side-drawer.open {
   transform: translateX(0);
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
  z-index: 100;
  top: 0;
  right: 0;
}

.user-wel {
   font-size: 18px;
   padding: 20px 20px 30px 20px;
}

.font-14 {
   font-size: 14px;
}

.side-menu {
   margin: 0 20px 20px 20px;
   padding-top: 20px;
   border-top: 1px solid rgba(46, 89, 151, 0.3)
}

.side-menu-item {
   font-size: 16px;
   line-height: 150%;
   color: #000000;
   padding: 15px 0;
}

.pop-up {
  background-color: #fff;
  z-index: 200;
  position: fixed;
  margin: auto;
  color: #000;
  min-width: 280px;
  padding: 40px;
  border: 1px solid #DFDFDF;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close-popup {
  position: absolute;
  right: 20px;
  top: 20px;
  line-height: 12px;
}

@media(min-width: 576px) {
   .side-drawer {
      width: 320px;
   }
}
.header {
  background-color: #62B04D;
  padding: 5px 16px;
  line-height: 45px;
  font-size: 20px;
  color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}

.head-img {
  height: 45px;
  vertical-align: middle;
}

.back-btn {
  vertical-align: middle;
}

.lgt-cta {
  right: 0;
  position: absolute;
  background-color: #fff;
  color: black;
  padding: 10px 30px;
  font-size: 16px;
  line-height: 20px;
  border-radius: 2px;
}

.ellipse {
  background-color: #F50000;
  height: 7px;
  width: 7px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#ser-b-name {
  opacity: 0;
}

.header-search {
  line-height: normal;
  max-width: 800px;
  margin: auto;
}
.store-web-header {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  background-color: rgba(98, 176, 77, 0.3);
}

.store-web-header .store-name {
  flex-grow: 1;
  padding: 0 20px;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.marker {
  margin-right: 5px;
  height: 11px;
}

.transparent-bg {
  background: transparent;
}

.store-label-img {
  height: 80px;
}

.store-cover-mob {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(98, 176, 77, 0.2);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  height: 250px;
  padding: 5px 16px;
  position: relative;
  margin-top: -66px;
}

.store-contact-wrapper {
  background-color: #fff;
  color: #000;
  padding: 20px 20px 15px 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12);
}

.touch-head {
  font-size: 24px;
  line-height: 31px;
  font-weight: 500;
}

.touch-line {
  color: #8F8F8F;
  font-size: 14px;
  line-height: 18px;
}

.touch-number {
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #559943;
}


@media screen and (max-width: 600px) {  
  .touch-head {
    font-size: 20px;
  }

  .touch-line {
    font-size: 14px;
  }

  .touch-number {
    font-size: 16px;
  }
}
.gc-wrapper, .gc-transactions, .gc-wrapper *, .gc-transactions * {
  box-sizing: border-box;
}

.gc-wrapper .loader, .gc-transactions .loader {
  box-sizing: content-box;
}

.container+.container {
  margin-top: 1.25em;
}

.gc-wrapper {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.lg-bg {
  background: linear-gradient(180deg, #209500 0%, #62b04d 100%);
  padding-top: 55px;
  padding-bottom: 1.5em;
  color: white;
  text-align: center;
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  position: relative;
  padding-bottom: 3.25em;
  min-height: 16em;
}

.gc-pitch {
  min-height: 12em;
}

.fw-500 {
  font-weight: 500;
}

.fw-700 {
  font-weight: 700;
}

.gc-steps {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(80px, auto));
  margin: 1em 10px;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}

.gc-step {
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  text-align: left;
  padding: 0.75em;
  line-height: 150%;
  max-width: 140px;
}

.gc-step-img {
  width: 60%;
}

.gc-pitch-title {
  background: #2e7b30;
  border: 2px solid #ffe883;
  width: 100%;
  text-align: center;
  font-weight: 500;
  padding: 0.5em;
}

.giftbox {
  padding: 1em 0;
}

.gc-btn {
  color: white;
  font-weight: 700;
  background: #62b04d;
  box-shadow: 2px 2px 6px rgba(17, 17, 17, 0.2);
  border-radius: 4px;
  text-align: center;
  padding: 10px;
  align-self: center;
  width: calc(100% - 20px);
  max-width: calc(3 * 140px + 2 * 10px);
  position: fixed;
  bottom: 15px;
  cursor: pointer;
}

.money-saved {
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  color: #111111;
  width: 90%;
  max-width: 600px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2em;
  padding: 10px 20px;
  text-align: start;
  min-height: 4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.money-saved .view {
  color: #62B04D
}

.CREDIT, .DEBIT {
  display: flex;
  align-content: center;
  justify-content: flex-end;
}

/* .red-text {
  color: #F44336;
} */
.DEBIT {
  color: #F44336;
}

.DEBIT::before {
  content: "-";
}

.CREDIT {
  color: #00BD08;
}

.CREDIT::before {
  content: "+";
}

.gc-transactions {
  padding: .5em 1em;
}

.transaction-row {
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  border-radius: 4px;
  padding: .5em;
  align-items: center;
  min-height: 3.5em;
}

.jumbocoin {
  width: 1.25em;
  height: 1.25em;
}

.transaction-row+.transaction-row {
  margin-top: .5em;
}

.gc-header {
  color: #8F8F8F;
  padding: .5em;
}

/* helper classes */

.gc-txt-xl {
  font-size: 32px;
}

.gc-txt-lg {
  font-size: 18px;
}

.gc-txt-md {
  font-size: 16px;
}

.gc-txt-sm {
  font-size: 14px;
}

.gc-txt-xs {
  font-size: 12px;
}

@media (min-width: 768px) {
  .gc-txt-xl {
    font-size: 34px;
  }
  .gc-txt-lg {
    font-size: 20px;
  }
  .gc-txt-md {
    font-size: 18px;
  }
  .gc-txt-sm {
    font-size: 16px;
  }
  .gc-txt-xs {
    font-size: 14px;
  }
  .gc-steps {
    grid-gap: 20px;
    gap: 20px;
  }
  .gc-step {
    max-width: 160px;
    padding: 1.5em;
  }
  .gc-btn {
    max-width: calc(3 * 160px + 2 * 20px);
    position: relative;
    margin: auto;
  }
  .gc-transactions {
    padding: .5em 4em;
  }
}
.scheme-tab {
  text-transform: none !important;
  font-size: 15px !important;
  line-height: 18px !important;
  font-weight: 500 !important;
}

.target-outer {
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  padding: 12px 16px;
  margin-bottom: 10px;
}

.target-title {
  color: #111111;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.target-description {
  color: #111111;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.target-validity {
  color: #606060;
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px;
}

.back-bar-small:before {
  display: block;
  content: "";
  margin-top: 0;
  height: 4px;
  width: 100%;
  /*background-color: #d8d8d8;*/
  /*background-size: 10px 10px !important;*/
  background-position: 0 -23px;
}

.inner-bar-small {
  background-color: #58a159;
  height: 4px;
  margin-top: -4px;
  border-radius: 0px;
  width: 0;
  transition: width 0.6s ease;
}

.inner-bar-small-green {
  background-color: #58a159;
  height: 4px;
  margin-top: -4px;
  border-radius: 0px;
  width: 0;
  transition: width 0.6s ease;
}

.inner-bar-small-black {
  background-color: #606060;
  height: 4px;
  margin-top: -4px;
  border-radius: 0px;
  width: 0;
  transition: width 0.6s ease;
}

.values-and-rewards {
  height: 42px;
  margin-top: 3px;
  position: relative;
}

.stone {
  font-size: 12px;
  line-height: 18px;
}

.flags {
  position: relative;
}

.milestone-flag {
  bottom: -1px;
  float: left;
  position: absolute;
}

.milestone-statue {
  bottom: 2px;
  float: left;
  position: absolute;
}

.progress-target {
  margin-right: 11px;
}

.current-indicator {
  position: absolute;
  bottom: 27px;
  padding: 2px 8px;
  text-align: center;
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}

.reward {
  font-size: 12px;
  line-height: 18px;
}

.target-message {
  display: flex;
  justify-content: center;
  margin-top: 17px;
  text-align: center;
  margin-bottom: 7px;
}

.message-container {
  border-radius: 18px;
  text-align: center;
  line-height: 18px;
  font-size: 12px;
  padding: 9px 20px;
  background-color: #ffeac6;
  color: #111111;
}

.achieved-target-img {
  margin-left: calc(100% - 43px);
  height: 54px;
}

.cong-text {
  font-size: 12px;
  line-height: 16px;
}

.cong-reward {
  margin-top: 3px;
  font-size: 15px;
  line-height: 16px;
  font-weight: bold;
}

.values-and-points-box {
  float: left;
  position: absolute;
  text-align: right;
}

.greenText {
  color: #58a159 !important;
}

.no-targets-for-now {
  padding: 0 60px;
  text-align: center;
  margin-top: 10%;
}

.no-past-scheme {
  padding: 0 60px;
  text-align: center;
  margin-top: 10%;
  color: #212121;
  font-size: 16px;
  font-weight: 500;
}

.no-targets-for-now .label {
  font-size: 18px;
  color: #111111;
  line-height: 18px;
}

.no-targets-for-now .text {
  font-size: 13px;
  line-height: 22px;
  color: #606060;
  margin-top: 13px;
}

.no-targets-for-now .home-btn {
  margin-top: 40px;
}

.new-target-alert {
  border: 1px solid #da5c34;
  border-radius: 10px;
  color: #da5c34;
  padding: 3px 8px 2px 8px;
  font-size: 10px;
  line-height: 14px;
  margin-left: 10px;
}

.days-left-alert {
  border: 1px solid #da5c34;
  border-radius: 4px;
  color: #da5c34;
  font-size: 12px;
  line-height: 14px;
  padding: 3px 8px;
}

.target-completed-alert {
  background-color: #58a159;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  padding: 3px 8px;
}

.details-outer {
  background-color: #2c552d;
  padding: 17px 25px 42px 25px;
}

.details-outer .scheme-name {
  color: #ffffff;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
}

.details-outer .scheme-description {
  color: #ffffff;
  font-size: 13px;
  line-height: 15px;
  margin-top: 6px;
  font-weight: 500;
  opacity: 0.9;
}

.details-outer .scheme-validity {
  color: #ffffff;
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
  opacity: 0.78;
}

.target-details {
  padding: 0 10px;
}

.details-reward {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  color: #000000;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding: 16px;
  margin-top: -24px;
}

.trns-history {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  color: #000000;
  font-size: 14px;
  line-height: 16px;
  padding: 16px;
  margin-top: 10px;
}

.scheme-rules {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  padding: 12px 16px;
  margin-top: 10px;
}

.scheme-rules .scheme-rule-label {
  color: #000000;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.scheme-rules .scheme-rule-title {
  color: #111111;
  font-size: 12px;
  line-height: 20px;
}

.warning-banner {
  background-color: #fffdde;
  padding: 14px 47px;
}

.warning-text {
  font-size: 12px;
  color: #727272;
  font-style: italic;
  font-weight: 500;
  line-height: 14px;
}

.top-scheme-item {
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  padding: 8px 16px;
  margin-top: 10px;
}

.top-scheme-item .title {
  color: #000000;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 0;
}

.included-transactions {
  padding: 16px 10px;
}

.included-transactions .label {
  color: #111111;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
}

.included-transactions .transactions {
  padding-top: 12px;
}

.target-transaction {
  background-color: #ffffff;
  padding: 18px 14px;
  border: 1px solid #cdcaca;
}

.target-txn-label {
  color: #606060;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.target-txn-amount {
  color: #111111;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
}

.schemes-outer {
  padding: 0px 10px;
  /*  background-color: #f7f7f7;
*/
}

.credit-text {
  color: #58a159;
}

.debit-text {
  color: #d0021b;
}

.scheme-rule-tag {
  color: #606060;
  font-size: 13px;
  line-height: 17px;
  margin: 10px 0;
}

.rule-text {
  font-size: 12px;
  line-height: 20px;
  color: #111111;
  padding: 6px 15px;
}

.rule-include-text {
  font-size: 12px;
  line-height: 20px;
  color: #58a159;
  padding: 6px 15px;
  border-left: 1px solid #c6c6c6;
}

.rule-exclusion-text {
  font-size: 12px;
  line-height: 20px;
  color: #d0021b;
  padding: 6px 15px;
  border-left: 1px solid #c6c6c6;
}

.detail-reward {
  color: #000;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
}

.jt-col-12 {
  width: 12%;
  float: left;
  position: relative;
}

.jt-col-78 {
  width: 78%;
  float: left;
  position: relative;
}

.jt-col-15 {
  width: 15%;
  float: left;
  position: relative;
}

.jt-col-94 {
  width: 94%;
  float: left;
  position: relative;
}

.jt-col-100 {
  width: 100%;
  float: left;
  position: relative;
}

.jt-col-75 {
  width: 75%;
  float: left;
  position: relative;
}

.jt-col-70 {
  width: 70%;
  float: left;
  position: relative;
}

.jt-col-30 {
  width: 30%;
  float: left;
  position: relative;
}

.jt-col-25 {
  width: 25%;
  float: left;
  position: relative;
}

.jt-col-35 {
  width: 35%;
  float: left;
  position: relative;
}

.jt-col-50 {
  width: 50%;
  float: left;
  position: relative;
}

.jt-col-60 {
  width: 60%;
  float: left;
  position: relative;
}

.jt-col-40 {
  width: 40%;
  float: left;
  position: relative;
}

.jt-col-20 {
  width: 20%;
  float: left;
  position: relative;
}

.jt-col-15 {
  width: 15%;
  float: left;
  position: relative;
}

.jt-col-85 {
  width: 85%;
  float: left;
  position: relative;
}

.jt-col-80 {
  width: 80%;
  float: left;
  position: relative;
}

.jt-col-6 {
  width: 6%;
  float: left;
  position: relative;
}

.jt-col-65 {
  width: 65%;
  float: left;
  position: relative;
}

.jt-col-33 {
  width: 33%;
  float: left;
  position: relative;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.centralize {
  text-align: center;
}

.row {
  width: unset !important;
}

.business-credit-box {
    background-color: #fff;
    padding: 40px 20px 20px 20px;
  }
  .centralize {
    text-align: center;
  }
  .available-label {
    font-family: Roboto,sans-serif;
    opacity: .5;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: .5px;
    font-style: normal;
    font-weight: 400;
  }
  
  .available-balance {
    margin-top: 7px;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 1.2px;
    font-weight: 500;
    color: #4a4a4a;
  }
  .unavailabel-credit {
    color: #e46156;
    letter-spacing: 2.6px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
  }

  .credit-values {
    position: relative;
    margin-top: 6px;
    height: 40px;
  }

  .pull-left {
    float: left;
  }
  
  .pull-right {
    float: right;
  }

  .credit-values-label {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 19px;
    opacity: 0.5;
  }
  
  .credit-values-item {
    font-size: 12px;
    line-height: 19px;
    font-weight: 600;
    letter-spacing: 0.4px;
    opacity: 0.64;
  }
  .exceeded-used {
    color: #fc854e;
    font-size: 13px;
    line-height: 19px;
    font-weight: 500;
    letter-spacing: 0.4px;
  }
  .repaymentTenure {
    margin-top: 5px;
    border: 1px solid #979797;
    border-radius: 2px;
    padding: 7px;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    color: #979797;
  }
  .repaymentTenuretxt {
    padding: 2px;
  }
  .repaymentTenurevalue {
    font-weight: 500;
    color: #4a4a4a !important;
  }
  .autoDebitViewCardContainer {
    /*background-color: grey;*/
    background-color: #ffffff;
    /*margin: 5px 8px;*/
    margin: 5px;
    /*height: 25vh;*/
    border-radius: 5px;
    /*border: 1px solid black;*/
  }
  .autoDebitQuestionCardInnerContainer {
    background-color: #ffffff;
  /*padding: 15px 8px 15px 8px;*/
    margin: 5px 8px;
    height: 20vh;
    border-radius: 5px;
  }
  .autoDebitQuestionCardView {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .questionCardTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .autoDebitViewCardHalf {
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 2px;
  }
  .txn-label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #4a4a4a;
    font-size: 12px;
    color: #6e6e6e;
  }
  .autoDebitLinkText {
    font-size: 12px;
    color: #0645ad;
    text-decoration: underline;
  }
  .autoDebitImageHalf img {
    width: 85px;
    /*height: 140px;*/
    /*margin-top: 3%;*/
  }
  .autoDebitBtn {
    background-color: #68b569 !important;
    color: #ffffff;
    width: 100%;
    padding: 7px 10px !important;
    color: #fff !important;
    font-size: 12px !important;
    letter-spacing: 1.2px !important;
    font-weight: bold !important;
    border-radius: 0px 0px 10px 10px;
  }
  .autoDebitViewCardHalfProcessing {
    width: 60%;
    height: 23vh;
    display: flex;
    flex-direction: column;
    padding: 2px;
    /*border: 1px solid blue;*/
  }
  .autoDebitProcessingDialogue {
    margin-top: 4%;
  }
  
  .autoDebitProcessingDialogue > img {
    width: 20px;
    height: 20px;
    margin-right: 1%;
  }
  .autoDebitImageHalf img {
    width: 85px;
    /*height: 140px;*/
    /*margin-top: 3%;*/
  }
  .autoDebitViewCompletedCardContainer {
    background-color: #ffffff;
    /*margin: 5px 8px;*/
    margin: 5px;
    /*height: 13vh;*/
    border-radius: 5px;
    padding: 8px;
  }
  .autoDebitCompletedContainer {
    display: flex;
    flex-direction: row;
    margin: 5px 8px;
  }
  
  .autoDebitCompletedContainer img {
    width: 50px;
    height: 70px;
  }
  .autoDebitCompletedImageDiv {
    margin-top: 3%;
    margin-right: 1%;
  }
  .autoDebitCompletedDialogueDiv {
    display: flex;
    flex-direction: column;
  }
  .payments-box {
    margin-top: 15px;
    background-color: #f7f7f7;
    padding: 15px 8px 15px 8px;
  }
  .jt-col-100 {
    width: 100%;
    float: left;
    position: relative;
  }  
  .payments-info {
    padding: 0 8px;
    position: relative;
    height: 20px;
  }
  .payment-name {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    opacity: 0.85;
    font-weight: 500;
    color: #4a4a4a;
  }
  .payment-amount {
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 23px;
    font-weight: 500;
    color: #4a4a4a;
    padding: 0px 8px;
  }
  .submitted-bank-info {
    background-color: rgba(247, 231, 62, 0.29);
    color: #4a4a4a;
    line-height: 19px;
    font-size: 12px;
    border-radius: 6px;
    text-align: center;
    padding: 2px 10px;
    margin-top: 16px;
    position: relative;
  }
  
  .submitted-bank-info:before {
    content: " ";
    height: 0;
    position: absolute;
    width: 0;
    right: 34px;
    bottom: 23px;
    border: 10px solid transparent;
    border-bottom-color: rgba(247, 231, 62, 0.29);
  }
  .clearedNextPage {
    background-color: #ffffff;
    padding: 15px;
    line-height: 20px;
    font-size: 13px;
    color: #4a4a4a;
    opacity: 0.85;
    box-shadow: 0px 1px 3px 1px rgba(176, 176, 176, 0.5);
    margin: 20px 0;
  }  
  .jt-col-94 {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    width: 94%;
    float: left;
    position: relative;
  }
  .jt-col-60 {
    width: 60%;
    float: left;
    position: relative;
  }
  .jt-col-6 {
    width: 6%;
    float: left;
    position: relative;
  }
  .sider {
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    opacity: 0.3;
  }
  .submittedAmount {
    background-color: #f7b33e;
    color: #fff;
    border-radius: 2px;
  }
  .transaction-box {
    margin-top: 10px;
    background-color: #ffffff;
    box-shadow: 0px 1px 3px 1px rgba(176, 176, 176, 0.5);
    padding: 10px 15px;
  }
  .txn-due-date {
    color: #4a4a4a;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
  .txn-amount {
    font-size: 14px;
    line-height: 16px;
    color: #4a4a4a;
    letter-spacing: 0.4px;
    font-weight: 500;
  }
  .txn-status {
    color: #fff;
    font-size: 14px;
    line-height: 19px;
    padding: 0px 10px;
    border-radius: 4px;
    font-weight: 500;
    background-color: green;
    padding-top: 1px;
  }
  .DELAYED,
.PARTIALLY_REPAID,
.BOUNCED {
  background-color: #e47556;
}

.PENDING,
.SUBMITTED_TO_BANK,
.IN_HUB {
  background-color: #f7b33e;
}

.RECENTLY_CLEARED {
  background-color: #68b569;
}
.txn-data-box {
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
}
.center {
  text-align: center;
}

.txn-status-box {
  font-family: "Roboto", sans-serif;
  padding: 6px 35px;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  background: #f6b24b;
  border-radius: 2px;
  font-weight: 500;
}

.amount-due {
  margin-top: 17px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.543636px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.5;
}

.top-ten {
  margin-top: 10px;
}
.credit-values-label {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 19px;
  opacity: 0.5;
}
.credit-values-item {
  font-size: 12px;
  line-height: 19px;
  font-weight: 600;
  letter-spacing: 0.4px;
  opacity: 0.64;
}
.available-balance {
  margin-top: 7px;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 1.2px;
  font-weight: 500;
  color: #4a4a4a;
}
.credit-values-label {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 19px;
  opacity: 0.5;
}

.credit-values-item {
  font-size: 12px;
  line-height: 19px;
  font-weight: 600;
  letter-spacing: 0.4px;
  opacity: 0.64;
}
.available-balance {
  margin-top: 7px;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 1.2px;
  font-weight: 500;
  color: #4a4a4a;
}
.small-total-balance {
  color: #606060;
  font-size: 15px;
  font-weight: 500;
}

.available-balance {
  margin-top: 7px;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 1.2px;
  font-weight: 500;
  color: #4a4a4a;
}
.small-total-balance {
  color: #606060;
  font-size: 15px;
  font-weight: 500;
}

.bounced-reason-box {
  background-color: #fae4dc;
  line-height: 29px;
  border-radius: 14.5px;
  font-size: 13px;
  line-height: 18px;
  color: #111111;
  text-align: center;
  padding: 7px 15px;
  box-shadow: 0px 2px 4px 0px rgba(222, 222, 222, 0.8);
}

.bounced-reason-box-paid {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  background: #e3f3e4;
  line-height: 29px;
  opacity: 0.56;
  border-radius: 11px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: 7px 15px;
  font-weight: 500;
  color: #373737;
}

.bounced-reason-box-state {
  background-color: rgba(250, 230, 224, 0.56);
  line-height: 24px;
  border-radius: 11px;
  font-size: 12px;
  padding: 0 7px;
  color: #4a4a4a;
}
.white-bg-padding {
  background-color: #fff;
  padding: 10px 18px 10px 18px;
}
.cancelled-cehque-box {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.cancelled-cehque-box .title {
  color: #4a4a4a;
}

.cancelled-cehque-box .desc {
  color: #68b569;
}

.credit-provider {
  font-size: 13px;
  line-height: 19px;
}

.credit-provider .first-line {
  color: #6e6e6e;
}
.customFont {
  font-family: "Roboto", sans-serif;
  color: #6e6e6e;
}

.credit-provider-name {
  color: #606060;
  font-weight: 500;
}

.credit-provider-name {
  color: #606060;
  font-weight: 500;
}
.free-till {
  color: #4a4a4a;
  font-weight: 500;
}
.payment-detail-heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  line-height: 15px;
  color: #606060;
  padding: 0 18px;
  font-size: 13px;
  font-weight: 500;
}
.white-bg-padding {
  background-color: #fff;
  padding: 10px 18px 10px 18px;
}

.white-bg {
  background-color: #fff;
}

.txn-cycle {
  padding: 6px 18px;
  position: relative;
}
.txn-cycle-phase-outer {
  padding: 12px 0;
}
.txn-cycle-phase-outer {
  padding: 12px 0;
}
.connector-inside {
  border-left: 2px solid #68b569;
  position: absolute;
  height: calc(100%);
  top: 24px;
  left: 11px;
  z-index: 1;
}

.connector-inside-faded {
  border-left: 1px solid #cecece;
  position: absolute;
  height: calc(100%);
  top: 24px;
  left: 12px;
}
.flow {
  display: flow-root;
}
.txn-state-name {
  font-size: 13px;
  line-height: 24px;
  color: #4a4a4a;
  font-weight: 500;
}

.txn-state-name-faded {
  font-size: 13px;
  line-height: 24px;
  color: #cecece;
}
.txn-state-date {
  color: #6e6e6e;
  font-size: 13px;
  line-height: 24px;
}
.cheque-details {
  border: 1px solid #e5e5e5;
  padding: 11px 9px;
  margin: 6px 0 5px 0;
}
.cheque-no {
  font-size: 13px;
  color: #8f8f8f;
  line-height: 19px;
  line-break: anywhere;
}
.bank-name {
  font-size: 12px;
}
.state-due-date {
  font-size: 12px;
  color: #4a4a4a;
  line-height: 19px;
}
.DueDate {
  font-weight: 500;
}
.cheque-amount {
  font-size: 13px;
  color: #4a4a4a;
  font-weight: 500;
  line-height: 19px;
}
.bounced-reason-box-state {
  background-color: rgba(250, 230, 224, 0.56);
  line-height: 24px;
  border-radius: 11px;
  font-size: 12px;
  padding: 0 7px;
  color: #4a4a4a;
}
.top-ten {
  padding-top: 10px;
}
.state-penalty {
  background-color: rgba(255, 249, 173, 0.56);
  padding: 5px 10px;
  border-radius: 11px;
  margin-bottom: 5px;
  box-shadow: 0 4px 4px 0 #dedede;
}

.state-penalty-paid {
  background-color: #e2f3e3;
  padding: 5px 10px;
  border-radius: 11px;
  margin-bottom: 5px;
}
.penalty-applicable {
  font-size: 12px;
  color: #4a4a4a;
}
.cheque-amount {
  font-size: 13px;
  color: #4a4a4a;
  font-weight: 500;
  line-height: 19px;
}
.outer-slider {
  position: relative;
  padding: 0 15px 0 0;
}

.sider-new {
  top: 9px;
  position: absolute;
  transform: translateY(-50%);
}
.state-penalty-paid {
  background-color: #e2f3e3;
  padding: 5px 10px;
  border-radius: 11px;
  margin-bottom: 5px;
}
.penalty-applicable {
  font-size: 12px;
  color: #4a4a4a;
}
.cheque-amount {
  font-size: 13px;
  color: #4a4a4a;
  font-weight: 500;
  line-height: 19px;
}
.cheque-amount {
  font-size: 13px;
  color: #4a4a4a;
  font-weight: 500;
  line-height: 19px;
}
.connector-inside-faded {
  border-left: 1px solid #cecece;
  position: absolute;
  height: calc(100%);
  top: 24px;
  left: 12px;
}
.txn-state-name-faded {
  font-size: 13px;
  line-height: 24px;
  color: #cecece;
}

.offer-container{
    padding-right: 15px;
    padding-left: 15px;
    height: 400px;
}

.hennure-offer-img{
    width: 100%;
    height: auto;
}
