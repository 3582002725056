.txn-data-box {
  background-color: #ffffff;
  padding: 20px;
  text-align: center;
}
.center {
  text-align: center;
}

.txn-status-box {
  font-family: "Roboto", sans-serif;
  padding: 6px 35px;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  background: #f6b24b;
  border-radius: 2px;
  font-weight: 500;
}

.amount-due {
  margin-top: 17px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.543636px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.5;
}

.top-ten {
  margin-top: 10px;
}
.credit-values-label {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 19px;
  opacity: 0.5;
}
.credit-values-item {
  font-size: 12px;
  line-height: 19px;
  font-weight: 600;
  letter-spacing: 0.4px;
  opacity: 0.64;
}
.available-balance {
  margin-top: 7px;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 1.2px;
  font-weight: 500;
  color: #4a4a4a;
}
.credit-values-label {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 19px;
  opacity: 0.5;
}

.credit-values-item {
  font-size: 12px;
  line-height: 19px;
  font-weight: 600;
  letter-spacing: 0.4px;
  opacity: 0.64;
}
.available-balance {
  margin-top: 7px;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 1.2px;
  font-weight: 500;
  color: #4a4a4a;
}
.small-total-balance {
  color: #606060;
  font-size: 15px;
  font-weight: 500;
}

.available-balance {
  margin-top: 7px;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 1.2px;
  font-weight: 500;
  color: #4a4a4a;
}
.small-total-balance {
  color: #606060;
  font-size: 15px;
  font-weight: 500;
}

.bounced-reason-box {
  background-color: #fae4dc;
  line-height: 29px;
  border-radius: 14.5px;
  font-size: 13px;
  line-height: 18px;
  color: #111111;
  text-align: center;
  padding: 7px 15px;
  box-shadow: 0px 2px 4px 0px rgba(222, 222, 222, 0.8);
}

.bounced-reason-box-paid {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  background: #e3f3e4;
  line-height: 29px;
  opacity: 0.56;
  border-radius: 11px;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  padding: 7px 15px;
  font-weight: 500;
  color: #373737;
}

.bounced-reason-box-state {
  background-color: rgba(250, 230, 224, 0.56);
  line-height: 24px;
  border-radius: 11px;
  font-size: 12px;
  padding: 0 7px;
  color: #4a4a4a;
}
.white-bg-padding {
  background-color: #fff;
  padding: 10px 18px 10px 18px;
}
.cancelled-cehque-box {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
}

.cancelled-cehque-box .title {
  color: #4a4a4a;
}

.cancelled-cehque-box .desc {
  color: #68b569;
}

.credit-provider {
  font-size: 13px;
  line-height: 19px;
}

.credit-provider .first-line {
  color: #6e6e6e;
}
.customFont {
  font-family: "Roboto", sans-serif;
  color: #6e6e6e;
}

.credit-provider-name {
  color: #606060;
  font-weight: 500;
}

.credit-provider-name {
  color: #606060;
  font-weight: 500;
}
.free-till {
  color: #4a4a4a;
  font-weight: 500;
}
.payment-detail-heading {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  line-height: 15px;
  color: #606060;
  padding: 0 18px;
  font-size: 13px;
  font-weight: 500;
}
.white-bg-padding {
  background-color: #fff;
  padding: 10px 18px 10px 18px;
}

.white-bg {
  background-color: #fff;
}

.txn-cycle {
  padding: 6px 18px;
  position: relative;
}
.txn-cycle-phase-outer {
  padding: 12px 0;
}
.txn-cycle-phase-outer {
  padding: 12px 0;
}
.connector-inside {
  border-left: 2px solid #68b569;
  position: absolute;
  height: calc(100%);
  top: 24px;
  left: 11px;
  z-index: 1;
}

.connector-inside-faded {
  border-left: 1px solid #cecece;
  position: absolute;
  height: calc(100%);
  top: 24px;
  left: 12px;
}
.flow {
  display: flow-root;
}
.txn-state-name {
  font-size: 13px;
  line-height: 24px;
  color: #4a4a4a;
  font-weight: 500;
}

.txn-state-name-faded {
  font-size: 13px;
  line-height: 24px;
  color: #cecece;
}
.txn-state-date {
  color: #6e6e6e;
  font-size: 13px;
  line-height: 24px;
}
.cheque-details {
  border: 1px solid #e5e5e5;
  padding: 11px 9px;
  margin: 6px 0 5px 0;
}
.cheque-no {
  font-size: 13px;
  color: #8f8f8f;
  line-height: 19px;
  line-break: anywhere;
}
.bank-name {
  font-size: 12px;
}
.state-due-date {
  font-size: 12px;
  color: #4a4a4a;
  line-height: 19px;
}
.DueDate {
  font-weight: 500;
}
.cheque-amount {
  font-size: 13px;
  color: #4a4a4a;
  font-weight: 500;
  line-height: 19px;
}
.bounced-reason-box-state {
  background-color: rgba(250, 230, 224, 0.56);
  line-height: 24px;
  border-radius: 11px;
  font-size: 12px;
  padding: 0 7px;
  color: #4a4a4a;
}
.top-ten {
  padding-top: 10px;
}
.state-penalty {
  background-color: rgba(255, 249, 173, 0.56);
  padding: 5px 10px;
  border-radius: 11px;
  margin-bottom: 5px;
  box-shadow: 0 4px 4px 0 #dedede;
}

.state-penalty-paid {
  background-color: #e2f3e3;
  padding: 5px 10px;
  border-radius: 11px;
  margin-bottom: 5px;
}
.penalty-applicable {
  font-size: 12px;
  color: #4a4a4a;
}
.cheque-amount {
  font-size: 13px;
  color: #4a4a4a;
  font-weight: 500;
  line-height: 19px;
}
.outer-slider {
  position: relative;
  padding: 0 15px 0 0;
}

.sider-new {
  top: 9px;
  position: absolute;
  transform: translateY(-50%);
}
.state-penalty-paid {
  background-color: #e2f3e3;
  padding: 5px 10px;
  border-radius: 11px;
  margin-bottom: 5px;
}
.penalty-applicable {
  font-size: 12px;
  color: #4a4a4a;
}
.cheque-amount {
  font-size: 13px;
  color: #4a4a4a;
  font-weight: 500;
  line-height: 19px;
}
.cheque-amount {
  font-size: 13px;
  color: #4a4a4a;
  font-weight: 500;
  line-height: 19px;
}
.connector-inside-faded {
  border-left: 1px solid #cecece;
  position: absolute;
  height: calc(100%);
  top: 24px;
  left: 12px;
}
.txn-state-name-faded {
  font-size: 13px;
  line-height: 24px;
  color: #cecece;
}
