@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@200&display=swap");
.business-credit-box {
    background-color: #fff;
    padding: 40px 20px 20px 20px;
  }
  .centralize {
    text-align: center;
  }
  .available-label {
    font-family: Roboto,sans-serif;
    opacity: .5;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: .5px;
    font-style: normal;
    font-weight: 400;
  }
  
  .available-balance {
    margin-top: 7px;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 1.2px;
    font-weight: 500;
    color: #4a4a4a;
  }
  .unavailabel-credit {
    color: #e46156;
    letter-spacing: 2.6px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
  }

  .credit-values {
    position: relative;
    margin-top: 6px;
    height: 40px;
  }

  .pull-left {
    float: left;
  }
  
  .pull-right {
    float: right;
  }

  .credit-values-label {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 19px;
    opacity: 0.5;
  }
  
  .credit-values-item {
    font-size: 12px;
    line-height: 19px;
    font-weight: 600;
    letter-spacing: 0.4px;
    opacity: 0.64;
  }
  .exceeded-used {
    color: #fc854e;
    font-size: 13px;
    line-height: 19px;
    font-weight: 500;
    letter-spacing: 0.4px;
  }
  .repaymentTenure {
    margin-top: 5px;
    border: 1px solid #979797;
    border-radius: 2px;
    padding: 7px;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
    color: #979797;
  }
  .repaymentTenuretxt {
    padding: 2px;
  }
  .repaymentTenurevalue {
    font-weight: 500;
    color: #4a4a4a !important;
  }
  .autoDebitViewCardContainer {
    /*background-color: grey;*/
    background-color: #ffffff;
    /*margin: 5px 8px;*/
    margin: 5px;
    /*height: 25vh;*/
    border-radius: 5px;
    /*border: 1px solid black;*/
  }
  .autoDebitQuestionCardInnerContainer {
    background-color: #ffffff;
  /*padding: 15px 8px 15px 8px;*/
    margin: 5px 8px;
    height: 20vh;
    border-radius: 5px;
  }
  .autoDebitQuestionCardView {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .questionCardTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .autoDebitViewCardHalf {
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 2px;
  }
  .txn-label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #4a4a4a;
    font-size: 12px;
    color: #6e6e6e;
  }
  .autoDebitLinkText {
    font-size: 12px;
    color: #0645ad;
    text-decoration: underline;
  }
  .autoDebitImageHalf img {
    width: 85px;
    /*height: 140px;*/
    /*margin-top: 3%;*/
  }
  .autoDebitBtn {
    background-color: #68b569 !important;
    color: #ffffff;
    width: 100%;
    padding: 7px 10px !important;
    color: #fff !important;
    font-size: 12px !important;
    letter-spacing: 1.2px !important;
    font-weight: bold !important;
    border-radius: 0px 0px 10px 10px;
  }
  .autoDebitViewCardHalfProcessing {
    width: 60%;
    height: 23vh;
    display: flex;
    flex-direction: column;
    padding: 2px;
    /*border: 1px solid blue;*/
  }
  .autoDebitProcessingDialogue {
    margin-top: 4%;
  }
  
  .autoDebitProcessingDialogue > img {
    width: 20px;
    height: 20px;
    margin-right: 1%;
  }
  .autoDebitImageHalf img {
    width: 85px;
    /*height: 140px;*/
    /*margin-top: 3%;*/
  }
  .autoDebitViewCompletedCardContainer {
    background-color: #ffffff;
    /*margin: 5px 8px;*/
    margin: 5px;
    /*height: 13vh;*/
    border-radius: 5px;
    padding: 8px;
  }
  .autoDebitCompletedContainer {
    display: flex;
    flex-direction: row;
    margin: 5px 8px;
  }
  
  .autoDebitCompletedContainer img {
    width: 50px;
    height: 70px;
  }
  .autoDebitCompletedImageDiv {
    margin-top: 3%;
    margin-right: 1%;
  }
  .autoDebitCompletedDialogueDiv {
    display: flex;
    flex-direction: column;
  }
  .payments-box {
    margin-top: 15px;
    background-color: #f7f7f7;
    padding: 15px 8px 15px 8px;
  }
  .jt-col-100 {
    width: 100%;
    float: left;
    position: relative;
  }  
  .payments-info {
    padding: 0 8px;
    position: relative;
    height: 20px;
  }
  .payment-name {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    opacity: 0.85;
    font-weight: 500;
    color: #4a4a4a;
  }
  .payment-amount {
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 23px;
    font-weight: 500;
    color: #4a4a4a;
    padding: 0px 8px;
  }
  .submitted-bank-info {
    background-color: rgba(247, 231, 62, 0.29);
    color: #4a4a4a;
    line-height: 19px;
    font-size: 12px;
    border-radius: 6px;
    text-align: center;
    padding: 2px 10px;
    margin-top: 16px;
    position: relative;
  }
  
  .submitted-bank-info:before {
    content: " ";
    height: 0;
    position: absolute;
    width: 0;
    right: 34px;
    bottom: 23px;
    border: 10px solid transparent;
    border-bottom-color: rgba(247, 231, 62, 0.29);
  }
  .clearedNextPage {
    background-color: #ffffff;
    padding: 15px;
    line-height: 20px;
    font-size: 13px;
    color: #4a4a4a;
    opacity: 0.85;
    box-shadow: 0px 1px 3px 1px rgba(176, 176, 176, 0.5);
    margin: 20px 0;
  }  
  .jt-col-94 {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    width: 94%;
    float: left;
    position: relative;
  }
  .jt-col-60 {
    width: 60%;
    float: left;
    position: relative;
  }
  .jt-col-6 {
    width: 6%;
    float: left;
    position: relative;
  }
  .sider {
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    opacity: 0.3;
  }
  .submittedAmount {
    background-color: #f7b33e;
    color: #fff;
    border-radius: 2px;
  }
  .transaction-box {
    margin-top: 10px;
    background-color: #ffffff;
    box-shadow: 0px 1px 3px 1px rgba(176, 176, 176, 0.5);
    padding: 10px 15px;
  }
  .txn-due-date {
    color: #4a4a4a;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
  .txn-amount {
    font-size: 14px;
    line-height: 16px;
    color: #4a4a4a;
    letter-spacing: 0.4px;
    font-weight: 500;
  }
  .txn-status {
    color: #fff;
    font-size: 14px;
    line-height: 19px;
    padding: 0px 10px;
    border-radius: 4px;
    font-weight: 500;
    background-color: green;
    padding-top: 1px;
  }
  .DELAYED,
.PARTIALLY_REPAID,
.BOUNCED {
  background-color: #e47556;
}

.PENDING,
.SUBMITTED_TO_BANK,
.IN_HUB {
  background-color: #f7b33e;
}

.RECENTLY_CLEARED {
  background-color: #68b569;
}