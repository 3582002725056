:root {
  --ff-primary: "DM Sans";
}

*, *::before, *::after {
  font-family: var(--ff-primary);
  /* box-sizing: border-box; */
}

.App {
  min-height: 100vh;
}

.banner-image {
  width: 100%;
}

/*Store*/
.store-banner {
  width: 100%;
}

.errorText {
  color: red;
}

.j24-tooltip {
  position: absolute;
  color: #8F8F8F;
  top: -30px;
  font-size: 12px;
  line-height: 140%;
  border: 1px solid #8F8F8F;
  padding: 3px 10px;
  border-radius: 4px;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 12%);
}

.arrow_box {
 position: absolute;
  color: #8F8F8F;
  top: -35px;
  font-size: 12px;
  line-height: 140%;
  border: 1px solid #8F8F8F;
  padding: 3px 10px;
  border-radius: 4px;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 12%);
  right: 0;
  background-color: #fff;
}
.arrow_box:after, .arrow_box:before {
  top: 100%;
  left: 64%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}
.arrow_box:before {
  border-color: rgba(223, 223, 223, 0);
  border-top-color: #8F8F8F;
  border-width: 9px;
  margin-left: -9px;
}

/*.store-selector-wrapper {
  margin-top: 10px;
}
*/
.store-selector-header {
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
}

.top-ten {
  margin-top: 10px;
}

.card {
  box-shadow: 2px 2px 6px rgba(154, 153, 153, 0.2);
  border-radius: 4px;
  text-align: center;
  margin-bottom: 20px;
}

.pop-img {
  border-radius: 50%;
  max-width: 200px;
  max-height: 200px;
  text-align: center;
  margin: auto;
  background-color: gray;
}



/*Search*/
/*Group*/

.CWI {
  padding: 10px 0 0 10px;
}

.cwoi {
  padding: 10px 0 10px 10px;
}

.CIOLC-wrapper {
  border-radius: 10px;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.16));
}

.CIOLC-img {
  border-radius: 10px;
  width: 100%;
}

.HSWLTL-title {
  padding: 10px;
  padding-top: 0;
}

.I_W2T_M_C-img {
  height: 100px;
  max-width: 100%;
}

.I_W2T_M_C-wrapper {
  background: #FFFFFF;
  border: 1px solid #EFEEEE;
  box-sizing: border-box;
  box-shadow: 2px 2px 6px rgba(154, 153, 153, 0.2);
  border-radius: 4px;
  text-align: center;
}

.I_W2T_M_C-text-wrapper {
  padding: 5px;
}

.I_W2T_B_M_C-wrapper {
  text-align: center;
  box-shadow: 2px 2px 6px rgba(154, 153, 153, 0.2);
  border-radius: 2px;
  padding-bottom: 5px;
}

.I_W2T_B_M_C-img {
  height: 100px;
  max-width: 100%;
}

.IWSTLFC-wrapper {
  text-align: center;
  box-shadow: 2px 2px 6px rgba(154, 153, 153, 0.2);
  border-radius: 2px;
  padding-bottom: 5px;
}

.IWSTLFC-img {
  width: 100%;
}

.card-text {
  /*display: -webkit-box;*/
  display: grid;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 30px;
  overflow: hidden;
  padding: 0!important;
  font-size: 11px;
  padding: 0 5px!important;
}

.riwtmc-wrapper {
  text-align: center; 
  border: 1px solid #EEEEEE;
  background-color: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 2px 2px 6px rgba(154, 153, 153, 0.2);
  border-radius: 4px;
  padding-bottom: 5px;
  font-size: 13px;
}


.card5-img-box {
  height: 80px;
  padding-top: 12px;
  text-align: center
}

.card5-img {
  border-radius: 50%;
  height: 56px;
  width: 56px;
}

.login {
  padding: 15px;
  align-self: center;
  text-align: center;
  /*margin: 0px auto;*/
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  /*position: relative;*/
  width: 50%;
  box-shadow: 2px 2px 6px rgba(154, 153, 153, 0.2);
}

.form-input {
  padding: 15px;
  align-self: left;
  text-align: left;
  margin: 10px auto;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  /*position: relative;*/
  width: 50%;
  box-shadow: 2px 2px 6px rgba(154, 153, 153, 0.2);
}



.testi{
  text-align: center;
  margin: 0% 0px;
}

.address-container{
  padding: 10px 20px;

}

.btn-jt-green {
  background-color: #58a159;
  color: #fff;
  outline: none;
  margin: 5% 0px;
  align-self: center;
  text-align: center;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  height: 36px;
  width: 126px;
  text-align: center;
}

a {
  text-decoration: none;
  color: unset;
}

.iobc-img {
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.16));
  border-radius: 4px;
  width: 100%;
}

.group-left-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #000000;
  padding: 0 15px;
}

.group {
  margin: 10px 0;
}

.gw3c-children {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fill,minmax(25%,1fr));
  padding: 10px;
}

.selected-quantity-input {
  height: 32px;
  width: 42px;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;

  /* or 18px */
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 6px;

  color: #000000;
}

.selected-quantity-button {
  height: 32px;
  width: 32px;
  border: 1px solid #000000;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 2px;
  margin-left: 6px;
}

.fwcwi-children {
  padding: 0 10px;
}
.iobc-wrapper {
  cursor: pointer;
}



.vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.payment-header {
    color: #8F8F8F;
    font-size: 18px;
}

.payment-details-box {
    background-color: #F0F0F0;
    border-radius: 4px;
    padding: 9px 12px;
}

.payment-itm {
    color: #000000;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 10px;
}

.payment-itm:last-child {
  margin-bottom: 0;
}

.bigg {
    font-weight: 600;
}

.delivery-notes {
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  padding: 8px 15px;
  outline: none;
}

.padding-twenty {
  padding: 20px;
}

.top-twenty {
  margin-top: 20px;
}

.top-fifty {
  margin-top: 50px;
}

.top-six {
  margin-top: 6px;
}

.right-text {
  text-align: right;
}

.no-right-pad {
  padding-right: 0;
}

.centered {
  text-align: center;
}

.lr-10 {
  padding: 0 10px;
}
.lr-20 {
  padding: 0 20px;
}

.swiper-pagination-bullet-active {
  background: #62B04D!important;
}

/*.row {
  display: flex;
  width: 100%;
}

.col-1 {
  width:12%;
  float:left;
  position:relative
}

.col-2 {
  width:12%;
  float:left;
  position:relative
}

.col-3 {
  width:12%;
  float:left;
  position:relative
}

.col-4 {
  width:12%;
  float:left;
  position:relative
}

.col-5 {
  width:12%;
  float:left;
  position:relative
}

.col-6 {
  width:12%;
  float:left;
  position:relative
}

.col-7 {
  width:12%;
  float:left;
  position:relative
}

.col-8 {
  width:12%;
  float:left;
  position:relative
}

.col-9 {
  width:12%;
  float:left;
  position:relative
}

.col-12 {
  width:12%;
  float:left;
  position:relative
}
*/
.login {
  padding: 15px;
  align-self: center;
  text-align: center;
  /*margin: 0px auto;*/
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  /*position: relative;*/
  width: 50%;
  box-shadow: 2px 2px 6px rgba(154, 153, 153, 0.2);
}

.testi{
  text-align: center;
  margin: 15% 0px;
}

.btn-jt-green {
  background-color: #58a159;
  color: #fff;
  outline: none;
  margin: 5% 0px;
  align-self: center;
  text-align: center;
  border: 1px solid #dfdfdf;
  border-radius: 8px;
  height: 36px;
  width: 126px;
  text-align: center;
}


/*img {
  width: 100%;
}*/


.address-header{
    height: 40px;
    width: 100%;
    background-color: gray;
    display: flex;
    flex-direction: row;
    padding: 2%;
}

.in-mob-box {
  border: none;
  border-bottom: 1px solid #cccccc;
  border-radius: 2px;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.0px;
  padding: 10px 0;
  width: 100%;
  outline: none;
  background-color: #fff;
}


.fl-lbl {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  transform: translate(0px, 0px);
  transform-origin: top;

}

.fl-lbl-up {
  position: absolute;
  top: 0px;
  left: 0;
  padding: 0;
  background-color: #fff;
  transform: translate(0, -8px);
  transform-origin: center;  
  font-size: 12px!important;  
}

.entered {
  color: #62B04D!important;
}

.entered-box {
  border-color: #62B04D!important;
}

.lll {
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  color: #808080;
  font-size: 15px;
  font-weight: normal;
}

.top30{
  margin-top: 30px;
}

.input-login{
  text-align: center;
  align-self: center;
  margin-left: 25%;
  margin-right:25%;
  margin-top: 25%;
  width:50%;
}

.btn-login{
  background-color: #62B04D;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: bold;
  padding: 15px 40px;
  outline: none;
  border-radius: 4px;
  border: none;
}

.otp-box {
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-size: 20px;
  line-height: 24px;
  padding: 11px;
  width: 48px;
  outline: none;
  text-align: center;
  margin-right: 15px;
}

.otp-box:last-child {
  margin-right: 0;
}

.otp-lbl {
  font-size: 18px;
  line-height: 150%;
  color: #000000;
}

.btn-add-address{
  background-color: white;
  padding: 15px;
  outline: none;
  width: 100%;
  border: 1px solid black;
  border-radius: 4px;

}

.pad-left-15 {
  padding-left: 15px;
}

.row, .row-reverse {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.row-reverse {
  flex-direction: row-reverse;
}

.col-1 {
  -ms-flex: 0 0 8.33%;
  flex: 0 0 8.33%;
  max-width: 8.33%;
  position: relative;
  width: 100%;
}

.col-2 {
  -ms-flex: 0 0 16.66%;
  flex: 0 0 16.66%;
  max-width: 16.66%;
  position: relative;
  width: 100%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
  width: 100%;
}

.col-4 {
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  position: relative;
  width: 100%;
}

.col-5 {
  -ms-flex: 0 0 41.66%;
  flex: 0 0 41.66%;
  max-width: 41.66%;
  position: relative;
  width: 100%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
}

.col-7 {
  -ms-flex: 0 0 58.33%;
  flex: 0 0 58.33%;
  max-width: 58.33%;
  position: relative;
  width: 100%;
}

.col-8 {
  -ms-flex: 0 0 66.66%;
  flex: 0 0 66.66%;
  max-width: 66.66%;
  position: relative;
  width: 100%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
  position: relative;
  width: 100%;
}

.col-10 {
  -ms-flex: 0 0 83.33%;
  flex: 0 0 83.33%;
  max-width: 83.33%;
  position: relative;
  width: 100%;
}

.col-11 {
  -ms-flex: 0 0 91.66%;
  flex: 0 0 91.66%;
  max-width: 91.66%;
  position: relative;
  width: 100%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
}

.bt-mar-55 {
  margin-bottom: 55px
}

.left-5 {
  margin-left: 5px;
}
.left-10 {
  margin-left: 10px;
}

a {
  text-decoration: none;
}

.dotted-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.full-width{
  width: 100%;
}

button {
  outline: none;
  cursor: pointer;
}

input {
  outline: none;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.flex {display: flex;}
.relative { position: relative; }
.absolute { position: absolute; }
.justify-content-between { justify-content: space-between; }
.col {display: flex; flex-direction: column;}
.row.center, .col.center {
  justify-content: center;
  align-items: center;
}
.absolute.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.align-center{ align-items: center;}

.lg-view{
  display:none;
}
.sm-view{
  display:inline-block;
}
.px-10 { padding: 0 10px; }
.py-10 { padding: 10px 0; }
.trunc-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn:hover {
  opacity: 0.9;
}

.side-button {
  height: 88px;
  width: 44px;
  background-color: #FFFFFF;
  border: 2px solid #EEEEEE;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  text-align: center;
  line-height: 92px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  cursor: pointer;
}

.left-bx {
  left: -8.33%;
  position: absolute;
  height: 100%;
}

.right-bx {
  right: calc(-8.33% + 48px);
  position: absolute;
  height: 100%;
}

.non-usable {
  opacity: 0.4;
}

@media screen and (min-width: 576px) {
  .lg-view{
    display:inline-block;
  }
  .sm-view{
    display:none;
  }

  .group {
    margin: 20px 0;
  }
  .gw3c-children {
    grid-template-columns: repeat(auto-fill,minmax(150px,1fr));
    justify-content: center;
    grid-gap: 20px;
  }
  .card-text {
    font-size: 13px;
    height: 40px;
  }
  .HSWLTL-children .IWSTLFC-img {
    width: 250px;
    height: 150px;
    object-fit: cover;
  }

  .HSWLTL-children .swiper-slide {
    width: auto  !important;
  }

  .store-label-img {
    height: 100px;
  }

  .store-cover {
    height: 365px;
  }
  
}

@media screen and (min-width: 768px) {
 .uinode {
    width: 600px;
    margin: auto;
  }

  .tab {
    margin-left: calc(-100vw / 2 + 600px / 2);
    margin-right: calc(-100vw / 2 + 600px / 2);
  }
}

@media screen and (min-width: 900px) {
 .uinode {
    width: 700px;
    margin: auto;
  }

  .tab {
    margin-left: calc(-100vw / 2 + 700px / 2)!important;
    margin-right: calc(-100vw / 2 + 700px / 2)!important;
  }
}

@media screen and (min-width: 1000px) {
 .uinode {
    width: 800px;
    margin: auto;
  }

  .tab {
    margin-left: calc(-100vw / 2 + 800px / 2)!important;
    margin-right: calc(-100vw / 2 + 800px / 2)!important;
  }
}

@media screen and (min-width: 1200px) {
 .uinode {
    width: 900px;
    margin: auto;
  }

  .tab {
    margin-left: calc(-100vw / 2 + 900px / 2)!important;
    margin-right: calc(-100vw / 2 + 900px / 2)!important;
  }
}

@media screen and (min-width: 1400px) {
 .uinode {
    width: 1000px;
    margin: auto;
  }

  .tab {
    margin-left: calc(-100vw / 2 + 1000px / 2)!important;
    margin-right: calc(-100vw / 2 + 1000px / 2)!important;
  }
}

@media screen and (min-width: 1600px) {
 .uinode {
    width: 1300px;
    margin: auto;
  }

  .tab {
    margin-left: calc(-100vw / 2 + 1300px / 2)!important;
    margin-right: calc(-100vw / 2 + 1300px / 2)!important;
  }
}

/* cradit */

/* progress bar */
.back-bar:before {
  display: block;
  content: "";
  margin-top: 0;
  height: 10px;
  width: 100%;
  background-color: #fff0d0;
  background-size: 10px 10px!important;
  background-position: 0 -23px;
}
.inner-bar {
  background-color: #ffc83c;
  height: 10px;
  margin-top: -10px;
  border-radius: 2px;
  width: 0;
  -webkit-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease;
}
.unlock-credit .large-text,
.title-text {
  color: #303030;
  font-size: 17px;
  font-weight: 600;
}

.unlock-credit .title-text {
  font-size: 18px;
}

.unlock-credit {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  margin-bottom: 45px;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
}
.hta {
  text-align: center;
  font-weight: 500;
}

.hta-img-wrapper {
  position: relative;
}

.hta-item-num {
  position: absolute;
  top: 0;
  background: #fce27f;
  font-size: 12px;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.hta-items {
  display: flex;
  padding: 30px 10px;
  margin: auto;
  justify-content: center;
}

.hta .title-text {
  position: relative;
}

.hta .title-text::after {
  content: "";
  position: absolute;
  display: block;
  width: 20%;
  height: 2px;
  background: black;
  left: 50%;
  transform: translate(-50%, 5px);
}

.hta-item {
  position: relative;
  max-width: 80px;
}

.hta-text {
  font-size: 14px;
  font-weight: 500;
}

.hta-img {
  width: 25px;
}

.hta-img.arrow {
  margin: 0 10px;
}

.apply-now-cta,
.apply-now-cta-gray {
  padding: 15px;
  color: #fff;
  background: #68b569;
  letter-spacing: 1.2px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  position: fixed !important;
  bottom: 0 !important;
  font-weight: 700;
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}
.apply-now-cta-gray {
  background: gray;
  cursor: not-allowed;
  pointer-events: none;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.benefit + .benefit {
  margin-top: 5px;
}

.unlock-credit * {
  box-sizing: border-box;
}

.unlock-credit .small-text {
  font-weight: 500;
  font-size: 14px;
  color: gray;
  line-height: 16px;
}

.unlock-credit-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.unlock-credit .banner-img {
  width: 100%;
}

.unlock-credit-text {
  position: absolute;
  background: #f4f4f4;
  padding: 10px;
  bottom: -22px;
  letter-spacing: 1.2px;
  text-align: center;
  width: 100%;
}

.unlock-credit .benefits {
  padding: 20px 10px;
  margin: 15px 0 20px 0;
}

.unlock-credit .benefit {
  display: flex;
  padding: 10px 5px;
}
.hta .title-text {
  position: relative;
}
.delayed-grp-ctas .pay-now {
  font-family: "Roboto", sans-serif;
  padding: 8px 16px;
  color: #fff;
  background: #68b569;
  letter-spacing: 1.2px;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  border-radius: 3px;
}
.delayed-grp-ctas .view-details {
  font-family: "Roboto", sans-serif;
  padding: 6px 6px;
  color: #303030;
  border: 1px solid #303030;
  letter-spacing: 1.2px;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  border-radius: 3px;
}