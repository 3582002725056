.tab {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 0 10px;
  border-bottom: 1px solid #dfdfdf;
  position: sticky;
  top: 55px;
  z-index: 99;
  background-color: #efefef;
  margin: 0 -20px;
}

.tab-option {
  padding: 5px;
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 40px;
  color: #8f8f8f;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin: 10px 20px;
}

.tab-option:hover {
  cursor: pointer;
}

.tab-option.active {
  border-color: #62b04d;
  font-weight: 600;
  color: #62b04d;
}

.swiper-container {
  width: 100% !important;
  box-sizing: border-box;
}

@media screen and (max-width: 576px) {
  .tab {
    margin: 0;
  }

  .tab-option {
    margin: 10px 5px;
  }
}
