.login-form {
  max-width: 300px;
  margin: auto;
  text-align: center;
  padding: 0 20px;
}
.guest-checkout {
  color: #62B04D;
  font-weight: 700;
  font-size: 16px;
}